import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { URLS } from '../../helpers/URLS';
import useAxios from '../../helpers/Hooks/useAxios';
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import EmpDetails from './EmpDetails';
import PaginationGlobal from '../TablePagination.js/PaginationGlobal';
import "../../components/AdminHeader/AdminHeader.scss"

const SearchSkills = () => {

  const [search, setSearch] = useState('');

  const { fetchData } = useAxios();

  const [empSkillData, setEmpSkillData] = useState([]);

  const [searchResult, setSearchResult] = useState([]);

  const [currentEmp, setCurrentEmp] = useState([]);

  const [isModal, setIsModal] = useState(false);

  const [filterQuery, setFilterQuery] = useState('All');

  const arrFilter = ['All', 'In-Project', 'Pool', 'Training'];

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getEmpWithSkills();
  }, [])

  const getEmpWithSkills = async () => {
    const resp = await fetchData({ url: URLS.getEmpWithSkills, method: 'get' });
    let employees = resp.data;
    employees.forEach(emp => {
      emp.skill = '';
      emp.project = '';
      emp.skills.forEach((skill, index) => {
        emp.skill += (emp.skills.length - 1 === index) ? skill.name : skill.name + ',';
      })
      emp.projects.forEach((proj, index) => {
        emp.project += (emp.projects.length - 1 === index) ? proj.project.projectName : proj.project.projectName + ',';
      })
    })
    setEmpSkillData(employees);
    setSearchResult(employees);
  }

  useEffect(() => {
    filter();
  }, [filterQuery, search])

  const filter = (e) => {
    let emps = empSkillData;
    setPageNumber(1);
    if (filterQuery === 'All') {
      setSearchResult(emps.filter(emp => emp.skill.toLowerCase().includes(search.toLowerCase())))
    }
    else if (filterQuery === 'In-Project') {
      let filterResult = [];
      emps.filter(emp => emp.skill.toLowerCase().includes(search.toLowerCase())).forEach((item) => {
        item.projects && item.projects.length > 0 &&
          item.projects.forEach((prj) => {
            if (prj.project.projectName.toLowerCase() !== 'pool') {
              if (prj.project.projectName.toLowerCase() !== 'training') {
                if (!(filterResult.find((x) => x._id === item._id))) {
                  filterResult.push(item);
                }
              }
            }
          })
      })
      setSearchResult(filterResult);
    } else if(filterQuery === "Pool"){
      if(search)
      {
        emps =  emps.filter(emp => emp.skill.toLowerCase().includes(search.toLowerCase()))
      }
      emps.forEach(x=>{
        console.log("KK",x.project)
      })
      const unAssigned = emps.filter(emp=>!emp.project)
      const list = emps.filter(emp=>emp.project?.toLowerCase().trim() === "ML-Administration".toLowerCase())
      setSearchResult([...list,...unAssigned])

    }
    else if (filterQuery !== 'All' || filterQuery !== 'In-Project') {
      let filterResult = [];
      emps.filter(emp => emp.skill.toLowerCase().includes(search.toLowerCase())).forEach((item) => {
        item.projects && item.projects.length > 0 &&
          item.projects.forEach((prj) => {
            if (prj.project.projectName.toLowerCase() === filterQuery.toLowerCase()) {
              if (!(filterResult.find(x => x._id === item._id))) {
                filterResult.push(item);
              }
            }
          })
      })
      setSearchResult(filterResult);
    }
  }

  const hideModal = () => {
    setIsModal(!isModal);
  }

  const pull_data = (data) => {
    setPageNumber(data)
  }

  const countPerPage = 10;

  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          <TextField label={'Skill Search'} placeholder={'Search'} sx={{ ml: 1 }} style={{ backgroundColor: 'white' }} fullWidth
            onChange={(e) => setSearch(e.target.value)}
            size='small'
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          ></TextField>
        </Grid>
        <Grid item xs={3} sx={{ ml: 4 }}>
          <TextField select label='Project' size='small' fullWidth id={'drpProject'}
            style={{ backgroundColor: 'white' }} value={filterQuery}
            onChange={(e) => setFilterQuery(e.target.value)} >
            {
              arrFilter.map((item,index) =>
                <MenuItem  key={index} value={item}>{item}</MenuItem>
              )
            }
          </TextField>
        </Grid>
      </Grid>
      <div className='bg-wht mt-3'>
        <div className='table-responsive tab-styles'>
          <table className='table'>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th scope='col'>Employee Name</th>
                <th scope='col'>Project</th>
                <th scope='col'>Skills</th>
              </tr>
            </thead>
            <tbody>
              {searchResult && searchResult.slice((pageNumber - 1) * countPerPage, pageNumber * countPerPage).map((emp, index) =>
                <tr key={index}>
                  <td>{emp.employeeCode}</td>
                  <td><Link component={'button'} underline='none'
                    onClick={() => { setCurrentEmp(emp); setIsModal(!isModal) }}
                  >{emp.employeeName}</Link></td>
                  <td> {emp.project ? emp.project : '--'}</td>
                  <td>{emp.skill ? emp.skill : '--'}</td>
                </tr>
              )
              }
            </tbody>
          </table>
          {searchResult && searchResult.length > 0 ? <PaginationGlobal count={Math.ceil(searchResult.length / countPerPage)} pageNumber={pageNumber}
            func={pull_data} /> : ''}
        </div>
      </div>
      <EmpDetails show={isModal} data={currentEmp} hide={hideModal} />
    </div>
  )
}

export default SearchSkills
