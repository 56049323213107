import React, { useState } from "react";
import { downloadLink } from "../../helpers/constFIles";
import { Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallIcon from "@mui/icons-material/Call";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ToolTip from "../../Tolltip";
import EditIcon from '@mui/icons-material/Edit';
import ResumeViewModal from "./ResumeViewModal";
import MUILink from '@mui/material/Link';
import Button from "@mui/material/Button"
import ViewCandidateDetailsModal from "./ViewCandidateDetailsModal";
import "./CandidateDetails.scss";


const CandidateCard = ({ pool, cardClass, setAddCandidateModal, setSelectedCandidateForEdit, showEditBtn }) => {

    const [showCandidates, setShowCandidates] = useState(false);

    const [candidate, setCandidate] = useState({})

    const particularCandidate = (candi) => {
        setCandidate({ ...candi })
        candidatesShow();
    }

    const [resumeShow, setResumeShow] = useState(false);

    const [resumeView, setResumeView] = useState({
        filePath: "",
        filetype: "",
        resumeName: ""
    })

    const candidatesShow = () => setShowCandidates(true);

    const candidatesClose = () => {
        setCandidate({});
        setShowCandidates(false);
    }

    const candidateResume = (data) => {
        setResumeView({
            ...resumeView,
            filePath: downloadLink + data.resumeUrl,
            filetype: data.resumeUrl.split(".").at(-1),
            resumeName: data.name
        })
        setResumeShow(true);
    }

    const resumeHide = () => {
        setResumeShow(false)
    }

    return (
        <div>
            <div className="row">
                {
                    pool.map((data,i) => {
                        return (
                            <div key={i} className='col-md-6 col-sm-12 p-0 col-lg-4'>
                                <div className={'rounded shadow bg-white m-2 ' + cardClass}>
                                    <CardContent>
                                        <div color="text.secondary">
                                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                                <div className='m-0 text-secondary' style={{ fontWeight: '600' }}>{data.job.jobTitle}</div>
                                                <div>
                                                    <IconButton size="small" onClick={() => { particularCandidate(data); }}>
                                                        <div><VisibilityIcon /></div>
                                                    </IconButton>
                                                    {showEditBtn ? <IconButton size="small" onClick={() => { setSelectedCandidateForEdit(data); setAddCandidateModal(true) }}>
                                                        <EditIcon />
                                                    </IconButton> : ''}
                                                </div>
                                            </div>
                                            <div className='row bg-light py-2 mb-2'>
                                                <div className='m-0 col-2 col-sm-2 col-xs-2 text-secondary d-flex justify-content-center' style={{ fontWeight: '600' }}>{data.candidateID}</div>
                                                <Box className='col-10 col-md-10 col-sm-10 MuiBox-root'>
                                                    <ToolTip Text={data.name} contentCalssNames={'overflow_card'}>
                                                        {data.name}
                                                    </ToolTip>
                                                </Box>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-2 col-sm-2 d-flex justify-content-center'>
                                                    <EmailOutlinedIcon className="text-secondary" />
                                                </div>
                                                <Box className='col-10 col-md-10 col-sm-10 MuiBox-root'>
                                                    <ToolTip Text={data.email} contentCalssNames={'overflow_card'}>
                                                        {data.email}
                                                    </ToolTip>
                                                </Box>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-2 col-sm-2 d-flex justify-content-center'><CallIcon className="text-secondary" /></div>
                                                <Box className='col-10 col-md-10 col-sm-10 MuiBox-root'>
                                                    <ToolTip Text={data.phone} contentCalssNames={'overflow_card'}>
                                                        {data.phone}
                                                    </ToolTip>
                                                </Box>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-2 col-sm-2 d-flex justify-content-center'><StarBorderIcon className="text-secondary" /></div>
                                                <Box className='col-10 col-md-10 col-sm-10 MuiBox-root'>
                                                    <ToolTip Text={data.skills} contentCalssNames={'overflow_card'}>
                                                        {data.skills}
                                                    </ToolTip>
                                                </Box>
                                            </div>
                                            <Box pt={2}>
                                                <MUILink onClick={() => {
                                                    candidateResume(data);
                                                }
                                                }><Button variant="text">Resume</Button></MUILink>
                                            </Box>
                                        </div>
                                    </CardContent>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <ResumeViewModal resumeData={resumeView} show={resumeShow} onHide={resumeHide} />
            {showCandidates ? <ViewCandidateDetailsModal candidateID={candidate.candidateID} show={showCandidates} onHide={candidatesClose} /> : null}
        </div>
    );
};

export default CandidateCard;
