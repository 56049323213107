import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { URLS } from "../../helpers/URLS";
import { toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Grid, InputLabel, Select, FormControl, MenuItem, OutlinedInput } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import EditIcon from "@mui/icons-material/Edit";
import useAxios from "../../helpers/Hooks/useAxios";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from '@mui/icons-material/Error';
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";

const Leaves = () => {

  const { fetchData } = useAxios();

  const [loadSkeleton, setLoadSkeleton] = useState(true);

  const [leaveTypes, setLeaveTypes] = useState([]);

  const [myLeaves, setMyLeaves] = useState([]);

  const [btnText, setBtnText] = useState("Submit");

  const [app, setApp] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const [currentLeaveReqId, setcurrentLeaveReqId] = useState("");

  const [leaveReq, setLeaveReq] = useState({
    leaveType: "",
    startDate: "",
    endDate: "",
    remarks: "",
    reason: "",
    documentUrl: "",
  });

  const [leaveReqErr, setLeaveReqErr] = useState({
    leaveType: "",
    startDate: "",
    endDate: "",
    remarks: "",
    documentUrl: "",
  });

  useEffect(() => {
    getLeaveTypes();
    getMyleaves();
  }, []);

  const getLeaveTypes = async () => {
    const resp = await fetchData({ url: URLS.getLeaveTypes, method: "get" });
    if (resp.status === 200) {
      setLeaveTypes(resp.data);
    }
  };

  const getMyleaves = async () => {
    setLoadSkeleton(true);
    const response = await fetchData({
      url: URLS.getMyLeaveRequests,
      method: "get",
    });
    if (response.status === 200) {
      setMyLeaves(response.data);
      setLoadSkeleton(false);
    }
  };

  const closeApplyLeaveModal = () => {
    setBtnText("Submit");
    setcurrentLeaveReqId("");
    setLeaveReq({
      leaveType: "",
      startDate: "",
      endDate: "",
      remarks: "",
      documentUrl: "",
    });
    setLeaveReqErr({ remarks: "" });
  };

  const handleCloseApprove = () => {
    setApp(false);
    closeApplyLeaveModal();
  }

  const handleOpenApprove = () => setApp(true);

  const handleChange = (e) => {
    setLeaveReq({
      ...leaveReq,
      [e.target.name]: e.target.value,
    });
    !leaveReqErr == e.target.value
      ? setLeaveReqErr({ [e.target.name]: [e.target.name] + "  required" })
      : setLeaveReqErr({ [e.target.name]: "" });
  };

  const submitForm = async () => {
    {
      if (btnText === "Submit") {
        const resp = await fetchData({
          url: URLS.createLeaveRequest,
          method: "post",
          body: leaveReq,
        });
        if (resp.status === 200) {
          setApp(false);
          toast.success(resp.message);
          getMyleaves();
        } else {
          toast.error(resp.message);
        }
        setLeaveReq({
          leaveType: "",
          startDate: "",
          endDate: "",
          remarks: "",
          documentUrl: "",
        });
      } else {
        const resp = await fetchData({
          url: URLS.updateLeaveRequest + "/" + currentLeaveReqId,
          method: "post",
          body: leaveReq,
        });
        if (resp.status === 200) {
          setApp(false);
          toast.success(resp.message);
          setcurrentLeaveReqId("");
          setBtnText("Submit");
          closeApplyLeaveModal();
          getMyleaves();
        } else {
          toast.error(resp.message);
          closeApplyLeaveModal();
        }
      }
    }
  };

  const pull_data = (data) => {
    setPageNumber(data)
  }

  const countPerPage = 10;

  return (
    <div className="">
      <div className="p-0">
        <div className="d-flex">
          <h4 className="title">My Leaves</h4>
          <div className="ms-auto">
            <Button variant="contained" onClick={handleOpenApprove} className="btn btn-primary mb-2">
              Apply Leave
            </Button>
          </div>
        </div>
        {
          loadSkeleton ? <SkeletonLoader /> : (
            myLeaves.length > 0 ?
              <div className="table-responsive bg-wht">
                <table className="table">
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Remarks</th>
                      <th>Reason</th>
                      <th>Status</th>
                      <th>Leave Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myLeaves.length > 0
                      ? myLeaves && myLeaves.slice((pageNumber - 1) * countPerPage, pageNumber * countPerPage).map((leave,index) => (
                        <tr key={index}>
                          <td>{moment(leave.startDate).format("DD-MM-YYYY")}</td>
                          <td>{moment(leave.endDate).format("DD-MM-YYYY")}</td>
                          <td>{leave.remarks}</td>
                          <td>{leave.reason ? leave.reason : "No Comments.."}</td>
                          <td className="text-capitalize">
                            {leave.status === "pending" ? (
                              <ErrorIcon className="text-warning me-2" />
                            ) : leave.status === "approved" ? (
                              <CheckCircleIcon className="text-success me-2" />
                            ) : leave.status === "rejected" ? (
                              <CancelIcon className="text-danger me-2" />
                            ) : null}
                            {leave.status}
                          </td>
                          <td>{leave.leaveType.leaveType}</td>
                          <td>
                            <Tooltip title="Edit Leaves" placement="top" arrow>
                              <span>
                              <button
                                disabled={leave.status === "approved"}
                                className="btn btn-primary p-0 me-1"
                                onClick={() => {
                                  setApp(true);
                                  setBtnText("Update");
                                  setcurrentLeaveReqId(leave.leaveRequestID);
                                  setLeaveReq({
                                    startDate: moment(leave.startDate).format(
                                      "YYYY-MM-DD"
                                    ),
                                    endDate: moment(leave.endDate).format(
                                      "YYYY-MM-DD"
                                    ),
                                    leaveType: leave.leaveType._id,
                                    remarks: leave.remarks,
                                  });
                                }}
                              >
                                <EditIcon className="p-1 text-white" />
                              </button>
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                      : ""}
                  </tbody>
                </table>
                {myLeaves && myLeaves.length > 0 ? <PaginationGlobal count={Math.ceil(myLeaves.length / countPerPage)} pageNumber={pageNumber} func={pull_data} /> : ''}
              </div>
              : <NoData />
          )
        }
        <div>
          <Modal show={app} onHide={handleCloseApprove} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Apply Leave</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Grid container spacing={4}>
                {/* <div className="col-md-4 col-sm-12"> */}
                  <Grid item xs={4} sx={{ padding: 1 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="leave-type">Leave Type</InputLabel>
                      <Select labelId="leave-type" id="leave-type" value={leaveReq.leaveType}
                        label="Leave Type" name="leaveType" onChange={handleChange} >
                        {leaveTypes && leaveTypes.map((each, index) =>
                          <MenuItem key={index} value={each._id}>{each.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                {/* </div> */}
                {/* <div className="col-md-4 col-sm-12"> */}
                  <Grid item xs={4} sx={{ padding: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="DD/MM/YYYY"
                        value={new Date(leaveReq.startDate)}
                        minDate={new Date()}
                        maxDate={new Date(leaveReq.endDate)}
                        onChange={(date) => setLeaveReq({ ...leaveReq, startDate: date })}
                        renderInput={(params) => <TextField {...params} fullWidth required
                        />}
                      />
                    </LocalizationProvider>
                  </Grid>
                {/* </div> */}
                {/* <div className="col-md-4 col-sm-12"> */}
                  <Grid item xs={4} sx={{ padding: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="DD/MM/YYYY"
                        disabled={leaveReq.startDate === "" ? true : false}
                        value={new Date(leaveReq.endDate)}
                        minDate={new Date(leaveReq.startDate)}
                        onChange={(date) => setLeaveReq({ ...leaveReq, endDate: date })}
                        renderInput={(params) => <TextField {...params} fullWidth required
                        />}
                      />
                    </LocalizationProvider>
                  </Grid>
                {/* </div> */}
              </Grid>
              <Grid item xs={12} sx={{mt:2}}>
                {/* <FormControl fullWidth >
                  <InputLabel id="remarks">Remarks</InputLabel>
                  <OutlinedInput
                    sx={{ padding: 2 }}
                    id="leave-remarks"
                    value={leaveReq.remarks}
                    onChange={handleChange}
                    name="remarks"
                    label="Remarks"
                  />
                </FormControl> */}
                <TextField id="outlined-basic" label="Remarks" variant="outlined" name="remarks" multiline rows={2} fullWidth value={leaveReq.remarks} onChange={handleChange}/>
              </Grid>
              {leaveReqErr.remarks && leaveReqErr.remarks.length > 0 ? (
                <small className="text-danger">{leaveReqErr.remarks}</small>
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="contained" color="inherit" onClick={handleCloseApprove} className="me-3">
                Close
              </Button>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={
                  leaveReq.leaveType === "" ||
                    leaveReq.startDate === "" ||
                    leaveReq.endDate === "" ||
                    leaveReq.remarks === ""
                    ? true
                    : false
                }
              >
                {btnText}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Leaves;

