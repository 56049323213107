import React, {useEffect, useState} from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const DateWithTimePicker = (props) => {

    const { defaultDate, getDate, label, minDate, minTime, maxDate, maxTime } = props;
    
    const [value, setValue] = useState('');

    useEffect(() => {
        if (defaultDate) {
            setValue(dayjs(new Date(defaultDate)))
        }
    }, [defaultDate])

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker label={label || 'Date'} value={value || undefined} 
                    onChange={(newValue) => { setValue(newValue); getDate(newValue.$d);}}
                    minDate={minDate ? dayjs(minDate) : null} minTime={minTime ? dayjs(minTime) : null}
                    maxDate={maxDate ? dayjs(maxDate) : null} maxTime={maxTime ? dayjs(maxTime) : null}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
        </div>
    )
}

export default DateWithTimePicker;





