import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { lightBlue } from '@mui/material/colors'
import { Tooltip } from "@mui/material";
import HubIcon from '@mui/icons-material/Hub';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SignpostIcon from '@mui/icons-material/Signpost';
import Wmlogo from "../../assets/images/logo.png"
import Avatar from '@mui/material/Avatar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StorageIcon from '@mui/icons-material/Storage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupsIcon from '@mui/icons-material/Groups';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import GroupIcon from '@mui/icons-material/Group';

import './AdminNav.scss'
import '../AdminHeader/AdminHeader.scss'

const AdminNav = ({ toggle, setToggle, menu, user }) => {

  const location = useLocation()
  
  const getIcon = (pathname) => {
    let icon = <DashboardIcon className="sidebar-icons mb-3" />;
    switch (pathname) {
      case '/dashBoard': icon = <DashboardIcon className="sidebar-icons mb-3" />; break;
      case '/status-report': icon = <AssignmentIndIcon className="sidebar-icons mb-3" />; break;
      case '/work-report': icon = <GroupIcon className="sidebar-icons mb-3" />; break;
      case '/project-report': icon = <AccountTreeIcon className="sidebar-icons mb-3" />; break;
      case '/manage-projects': icon = <StorageIcon className="sidebar-icons mb-3" />; break;
      case '/manage-team': icon = <GroupsIcon className="sidebar-icons mb-3" />; break;
      case '/searchskill': icon = <PersonSearchIcon className="sidebar-icons mb-3" />; break;
      case '/jobs': icon = <WorkIcon className="sidebar-icons mb-3" />; break;
      case '/pool': icon = <PersonSearchIcon className="sidebar-icons mb-3" />; break;
      case '/recruiter/list': icon = <DashboardIcon className="sidebar-icons mb-3" />; break;
      case '/approvals': icon = <DateRangeIcon className="sidebar-icons mb-3" />; break;
      case '/leaveRequests': icon = <TodayIcon className="sidebar-icons mb-3" />; break;
      default: icon = <DashboardIcon className="sidebar-icons mb-3" />; break;
    }

    return icon;
  }

  return (
    <div className="sidebar">
      <nav id="sidebar" style={{ height: '100%' }}>
        {
          toggle ? (
            <div className="sideNavbar_big">
              <NavLink to="/">
                <Tooltip title="Go To Home Page" placement="right" arrow>
                  <div className="d-flex justify-content-center p-1">
                    <img src={Wmlogo} alt="logo" className="" />
                  </div>
                </Tooltip>
              </NavLink>
              <hr className="text-white mt-0 mb-0"></hr>
              <div className="side_nav text-center">
                <Avatar sx={{ width: 56, height: 56, bgcolor: lightBlue[900] }} className="mx-auto mt-4">{Array.from(user.employeeName)[0]}</Avatar>
                <div className="m-3">
                  <h5 className="text-white text-center">{user.employeeName}</h5>
                  <p className="designation text-center mb-2">{user.employeeCode}</p>
                </div>
                {menu.map((m, index) => (
                  <Link key={m.path} to={m.path}>
                    <div className="d-flex align-items-center" style={location.pathname == m.path ? { borderBottom: "1px solid rgb(47 81 108)", color: "rgb(120, 164, 235)", backgroundColor: "rgb(23 55 80)", } : null}>
                      <div className="pt-2 m-1">
                        {getIcon(m.path)}
                      </div>
                      <h6 className="pull-left-text ms-3 mb-3 mt-3">{m.name}</h6>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="sideNavBar_small p-2 text-center">
                <div>
                  <div className="logo_small_letter pt-2 pb-1 px-1">
                    <div className="profile_icon text-white fw-bold ms-1 mb-2 pt-2" onClick={() => setToggle(!toggle)}><small>WM</small></div>
                  </div>
                  <Avatar sx={{ width: 30, height: 30, bgcolor: lightBlue[900] }} className="mb-4 mt-4 mx-auto">{Array.from(user.employeeName)[0]}</Avatar>
                  <div>
                    {menu.map((m, index) => (
                      <Link key={m.path} to={m.path}>
                        <div className="d-flex align-items-center mb-3" style={location.pathname == m.path ? { borderLeft: "solid 3px #78a4eb", color: "#78a4eb" } : null}>
                          <Tooltip title={m.name} placement='right' arrow>
                            <div className="ms-2 pt-2 text-left">
                              {getIcon(m.path)}
                              </div>
                          </Tooltip>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </nav>
    </div>
  );
};

export default AdminNav