import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { dateFormat } from "../../helpers/utilities";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Tooltip, Button, Grid, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import Select from 'react-select';
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import EndProject from "./EndProject";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";
import * as moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "../Recruiter/recruiter.css"

const ManageProjects = () => {

    const { fetchData } = useAxios();

    const [loadSkeleton, setLoadSkeleton] = useState(true);

    const [isLoad, setIsLoad] = useState(false);

    const [addProjectshow, setAddProjectshow] = useState(false);

    const [teamShow, setTeamShow] = useState(false);

    const [endProjectModal, setEndProjectModal] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);

    const [projectPayload, setProjectPayload] = useState({
        projectId: "",
        projectName: "",
        projectStartDate: new Date(),
        projectEndDate: new Date(),
        projectDescription: ""
    })

    const [projectList, setProjectList] = useState([]);

    const [employeesList, setEmployeesList] = useState([]);

    const [projectTeam, setProjectTeam] = useState({
        projectName: "",
        teamName: "",
        projectID: "",
        managerID: "",
        teamsList: [],
        managerIds: [String]
    })

    const onChangeHandler = (e) => {
        setProjectPayload({
            ...projectPayload,
            [e.target.name]: e.target.value
        })
    }

    const addProjectsModel = () => setAddProjectshow(!addProjectshow);

    const teamModel = () => setTeamShow(!teamShow);

    const endProjectMod = () => setEndProjectModal(!endProjectModal)

    const teamMangerChange = async (value) => {
        let arrEmpCodes = [];
        for (var i = 0; i < value.length; i++) {
            arrEmpCodes.push(value[i].employeeCode);
        }
        try {
            setProjectTeam({
                ...projectTeam,
                managerIds: arrEmpCodes
            })
        } catch (err) {
        }
    }

    const teamMembersChange = async (value) => {
        try {            
            setProjectTeam({
                ...projectTeam,
                teamsList: value.map(x => {
                    return { employeeCode: x.employeeCode }
                })
            })
        } catch (err) {
        }
    }

    const getProjectList = async () => {
        setLoadSkeleton(true);
        const resp = await fetchData({ url: URLS.getProjectList, method: 'get' });
        if (resp.status === 200) {
            setProjectList(resp.data);
            setLoadSkeleton(false);
        }
    }

    const getEmployeesList = async () => {
        const resp = await fetchData({
            url: URLS.searchEmployees, method: 'post', body: {
                search: ""
            }
        });
        if (resp.status === 200) {
            setEmployeesList(resp.data);
        }
    }

    useEffect(() => {
        getProjectList();
        getEmployeesList();
        setIsLoad(true);
    }, [isLoad]);

    const getPageNo = (data) => {
        setPageNumber(data)
    }

    const countPerPage = 10;

    return <div className='content'>
        <div className="d-flex ">
            <h4 className='title'>Manage Projects</h4>
            <div className='ms-auto'>
                <div className="text-end">
                    <Button type="button" variant="contained" onClick={(e) => {
                        setProjectPayload({
                            projectId: "",
                            projectName: "",
                            projectStartDate: new Date(),
                            projectEndDate: new Date(),
                            projectDescription: ""
                        })
                        addProjectsModel()
                    }}>Add Project</Button>
                </div>
            </div>
        </div>
        <div className="shadow-sm bg-white">
            <div className="card-body">
                <div className="table-responsive">
                    {
                        loadSkeleton ? <SkeletonLoader /> : (projectList.length > 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="col-text">Project ID</th>
                                        <th scope="col" className="col-text">Project Name</th>
                                        <th scope="col" className="col-text">Start Date</th>
                                        <th scope="col" className="col-text">End Date</th>
                                        <th scope="col" className="col-text">Status</th>
                                        <th scope="col" className="col-text">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {projectList.map((project) => { */}
                                    {projectList.slice((pageNumber - 1) * countPerPage,
                                    pageNumber * countPerPage).map((project) =>{
                                        return <tr key={project.projectId}>
                                            <td className="row-text" scope="row">{project.projectId}</td>
                                            <td className="row-text">{project.projectName}</td>
                                            <td className="row-text">{dateFormat(project.projectStartDate)}</td>
                                            <td className="row-text">{dateFormat(project.projectEndDate)}</td>
                                            <td>{moment(project.projectEndDate).isBefore(new Date(),'day') ? <p className="text-success">Completed</p> : <p className="text-warning">Running</p>}</td>
                                            {/* <td>{dateFormat(project.projectEndDate) >= dateNow ? <p className="text-success">Completed</p> : <p className="text-warning">In Progress</p>}</td> */}
                                            <td className="row-text">
                                                <Tooltip title="Team Members" placement="top" arrow>
                                                    <Button type="button" variant="outlined" className="me-3" onClick={(e) => {
                                                        const obj = {
                                                            projectName: project.projectName,
                                                            teamName: "",
                                                            projectID: project.projectId,
                                                            managerID: "",
                                                            teamsList: [],
                                                            managerIds: []
                                                        }
                                                        if (project.projectTeam && project.projectTeam.length) {
                                                            obj.teamName = project.projectTeam[0].teamName;
                                                            obj.teamsList = project.projectTeam[0].teamsList;
                                                            obj.managerID = project.projectTeam[0].managerID;
                                                            obj.managerIds = project.projectTeam[0].managerIds
                                                        }
                                                        setProjectTeam({
                                                            ...obj,
                                                        })
                                                        teamModel()
                                                    }}>Team ({project.projectTeam ? project.projectTeam.length ? project.projectTeam[0].teamsList.length : 0 : 0})</Button></Tooltip>
                                                <Tooltip title="Edit" placement="top" arrow>
                                                    <Button type="button" variant="outlined" onClick={((e) => {
                                                    setProjectPayload({
                                                        projectId: project.projectId,
                                                        projectName: project.projectName,
                                                        projectStartDate: new Date(project.projectStartDate),
                                                        projectEndDate: new Date(project.projectEndDate),
                                                        projectDescription: project.projectDescription
                                                    })
                                                    addProjectsModel()
                                                })} className="me-3">Edit</Button></Tooltip>
                                                <Button type="button" variant="outlined" disabled={moment(project.projectEndDate).isBefore(new Date(),'day') ?true:false}
                                                onClick={() => {
                                                    setEndProjectModal(true)
                                                }}>End Project</Button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>

                            : <NoData />
                        )
                    }
                        {projectList && projectList.length > 0 ?
                        <PaginationGlobal count={Math.ceil(projectList.length / countPerPage)}
                        pageNumber={pageNumber} func={getPageNo} /> : "" }

                </div>
            </div>
        </div>
        <div className='col-12'>
            <Modal show={addProjectshow} onHide={addProjectsModel} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{projectPayload.projectId ? 'Update' : 'Add'} Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" label="Project Name" variant="outlined" fullWidth onChange={onChangeHandler} value={projectPayload.projectName} name="projectName" required />
                        </Grid>

                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={projectPayload.projectStartDate}
                                    minDate={moment().toDate()}
                                    onChange={(date) => {
                                        setProjectPayload({ ...projectPayload, projectStartDate: date })
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth required
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="End Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={projectPayload.projectEndDate}
                                    minDate={moment().toDate()}
                                    onChange={(dt) =>
                                        {   
                                             setProjectPayload({ ...projectPayload, projectEndDate:dt})
                                        }
                                    }
                                    renderInput={(params) => <TextField {...params} fullWidth required
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" rows={2} multiline label="Project Description" variant="outlined" onChange={onChangeHandler} value={projectPayload.projectDescription} name="projectDescription" fullWidth />
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="inherit" className="me-3" onClick={addProjectsModel}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={async (e) => {
                        const resp = await fetchData({ url: URLS.manageProject, method: 'post', body: projectPayload });
                        if (resp.status === 200) {
                            addProjectsModel();
                            getProjectList();
                            toast.success(resp.message)
                        }
                        else{
                            toast.error(resp.message,'error')
                        }
                    }}
                        disabled={projectPayload.projectName && projectPayload.projectStartDate && projectPayload.projectEndDate ? false : true}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={teamShow} onHide={teamModel} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{projectTeam.projectName} - {projectTeam.projectID} - Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-md-12 row'>
                        <div className='col-md-12'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Team Name</label>
                            <input type="text" value={projectTeam.teamName} name="teamName" onChange={(e) => {
                                setProjectTeam({
                                    ...projectTeam,
                                    teamName: e.target.value
                                })
                            }} className="form-control" id="exampleFormControlInput1" placeholder="Team Name" />
                        </div>
                        <div className='col-md-12 mt-2'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Lead/Manager</label>
                            <Select
                                isMulti={true}
                                value={projectTeam.managerIds ? employeesList.filter(x => projectTeam.managerIds.find(y => x.employeeCode === y)) : []}
                                options={employeesList.filter((x => !(projectTeam.teamsList.find(y => y.employeeCode === x.employeeCode))))}
                                onChange={teamMangerChange}
                            />
                        </div>
                        <div className='col-md-12 mt-2'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Team Members ({projectTeam.teamsList.length})</label>
                            <Select
                                isMulti
                                value={employeesList.filter(x => projectTeam.teamsList.find(y => x.employeeCode === y.employeeCode))}
                                options={employeesList.filter((x => !(projectTeam.managerIds.find(y=>x.employeeCode === y))))}                                
                                onChange={teamMembersChange} />
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="inherit" onClick={teamModel} className="me-3">
                        Close
                    </Button>
                    <Button onClick={async (e) => {
                        const resp = await fetchData({ url: URLS.projectTeamManage, method: 'post', body: projectTeam });
                        if (resp.status === 200) {
                            teamModel();
                            getProjectList();
                            toast.success(resp.message)
                        }
                        else {
                            toast.error(resp.message, 'error')
                        }

                    }} variant="contained"
                     disabled={projectTeam.teamName ? false:true}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={endProjectModal} onHide={endProjectModal} backdrop="static" size="md">
                <Modal.Header closeButton onClick={endProjectMod}>
                    <Modal.Title>End Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EndProject />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="inherit" className="me-3" onClick={endProjectMod}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={endProjectMod}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
           
        </div>
    </div>
}

export default ManageProjects