import React from 'react'
import JoBPocImage from '../../assets/images/task-panel.jpg'
import InfoImage from '../../assets/images/reporting.jpg'
import TaskView from '../../assets/images/home-section3.jpg'
import './HomeContent.scss';

const HomeContent = () => {
    return (
        <div className='HomeContent-section'>
            <div>
                <div>
                    <div className='job_grid'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 offset-md-1'>
                                    <div className='border-0 mt-4'>
                                        <h2>LEAVE MANAGEMENT</h2>
                                        <p> Define your company leave policy Leave tracking and complete leave accounting Access accurate leave balance and transactions, anytime</p>
                                        <ul>
                                            <li>Fully customizable leave policies.</li>
                                            <li>Holiday lists.</li>
                                            <li>Minimal leave-related tasks and queries.</li>
                                            <li>Leave year-end processing.</li>
                                        </ul>
                                        <button className='border-0 btn btn-cost btn-primary mt-3'>Discover More</button>
                                    </div>
                                </div>
                                <div className='col-md-5'> <img src={JoBPocImage} className="img-fluid2 w-80" alt="JoBPocImage" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-two">
                        <div className="section-two-content col-md-4 col-sm-4 pos-left">
                            <div className="background-image-holder" style={{ background: `url(${TaskView})`, opacity: '1' }}>
                                <img src={TaskView} className="img-fluid" alt="InfoImage" />
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 offset-md-5 col-sm-8 offset-sm-3 ">
                                    <h2> WRMS makes it easy to add modules to your plan as your organization grows.</h2>
                                    <p>Ticketing, email, chat and phone support for the Standard pla. Smart HR management software.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='top_jobs'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-5 offset-md-1'>
                                    <div className='border-0 ml-4 '>
                                        <h2>ATTENDANCE MANAGEMENT</h2>
                                        <p>Capture real-time attendance from Access control / biometric machines</p>
                                        <ul>
                                            <li>
                                                <div className='icon'>O</div>
                                                <div>Attendance capture from varied sources</div> </li>
                                            <li>
                                                <div className='icon'>M</div>
                                                <div>Geo Mark Attendance Marking feature</div> </li>
                                            <li>
                                                <div className='icon'>C</div>
                                                <div>Attendance Processing
                                                </div>
                                            </li>
                                        </ul>
                                        <button className='border-0 btn-cost mt-3 btn btn-primary'>Discover More</button>
                                    </div>
                                </div>
                                <div className='col-md-5'> <img src={InfoImage} className="img-fluid" alt="InfoImage" /> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeContent