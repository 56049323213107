import { Tooltip } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { URLS } from "../../helpers/URLS";
import { toast } from 'react-toastify';
import useAxios from "../../helpers/Hooks/useAxios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment"
import ToolTip from '../../Tolltip'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from '@mui/icons-material/Error';
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";
import './LeavesPage.scss'

const Approvals = () => {

    const [show, setShow] = useState(false);

    const [app, setApp] = useState(false);

    const [loadSkeleton, setLoadSkeleton] = useState(true);

    const [leaveData, setLeaveData] = useState([])

    const { fetchData } = useAxios();

    const [leaveDetails, setLeaveDetails] = useState({
        leaveRequestID: " ",
        status: " ",
        reason: " "
    })

    const [pageNumber, setPageNumber] = useState(1);

    const handleClose = () => setShow(false);

    const handleCloseApprove = () => setApp(false);

    const handleOpenApprove = (item) => {
        setLeaveDetails({
            leaveRequestID: item.leaveRequestID,
            reason: item.reason
        })
        setApp(true)
    };

    const getTeamLevelLeaveRequest = async () => {
        setLoadSkeleton(true);
        const resp = await fetchData({ url: `${URLS.getTeamLevelLeaveRequest}`, method: 'get' });
        setLeaveData(resp.data);
        setLoadSkeleton(false);

    }

    const addStatus = async (type) => {
        const payload = {
            ...leaveDetails,
            status: type
        }
        const resp = await fetchData({ url: `${URLS.updateLeaveRequestStatus}`, method: 'post', body: payload });
        if (resp.status === 200) {
            toast.success(resp.message);
            getTeamLevelLeaveRequest();
            setApp(false)
        } else {
            toast.error(resp.message, 'error');
        }
    }

    useEffect(() => {
        getTeamLevelLeaveRequest();
    }, [])

    const pull_data = (data) => {
        setPageNumber(data)
    }

    const countPerPage = 10;

    return (
        <div className="">
            <h4 className="title">Team Leave Approvals</h4>
            <div className="bg-wht">
                {loadSkeleton ? <SkeletonLoader /> : (leaveData?.length > 0 ?
                    <div className="table-responsive tab-styles">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">EmpID</th>
                                    <th scope="col">Emp Name</th>
                                    <th scope="col">From</th>
                                    <th scope="col">To</th>
                                    <th scope="col">LeaveType</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaveData && leaveData.slice((pageNumber - 1) * countPerPage, pageNumber * countPerPage).map((item, index) =>
                                    <tr key={index} className="border">
                                        <td>{item.employeeCode}</td>
                                        <td>{item.requestedBy.employeeName}</td>
                                        <td className="leaves_appl_dates">{moment(item.startDate).format('DD-MM-YYYY')}</td>
                                        <td className="leaves_appl_dates">{moment(item.endDate).format('DD-MM-YYYY')}</td>
                                        <td className="leaves_appl_type">{item.leaveType.name}</td>
                                        <td className="text-capitalize">
                                            <ToolTip Text={<span className="text-capitalize">{item.remarks}</span>}>
                                                {item.remarks}
                                            </ToolTip>
                                        </td>
                                        <td className="text-capitalize">{item.reason ? item.reason : "No Comments.."}</td>
                                        <td className="text-capitalize"><div className="d-flex">{item.status === "pending" ? <ErrorIcon className="text-warning me-2" /> : item.status === "approved" ? <CheckCircleIcon className="text-success me-2" /> : item.status === "rejected" ? <CancelIcon className="text-danger me-2" /> : null}{item.status}</div></td>
                                        <td>
                                            <Tooltip title="View Request" placement="top" arrow>
                                                <span>
                                                <button className="btn btn-secondary p-0" disabled={item.status != 'pending'} onClick={(e) => handleOpenApprove(item)}><VisibilityIcon className='text-white p-1' /></button>
                                                </span>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {leaveData && leaveData.length > 0 ? <PaginationGlobal count={Math.ceil(leaveData.length / countPerPage)} pageNumber={pageNumber} func={pull_data} /> : ''}
                    </div> : <NoData />
                )}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">Rejecting</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to reject the leave request?
                        <textarea className="form-control mt-2" placeholder="Remarks" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                        <Button variant="primary" onClick={handleClose}>Send Message</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={app} onHide={handleCloseApprove}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-primary">Leave - Approval</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label className="fw-bold mb-1">Remarks:</label>
                        <textarea value={leaveDetails.reason} onChange={(e) => {
                            setLeaveDetails({
                                ...leaveDetails,
                                reason: e.target.value
                            })
                        }} className="form-control"></textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={async () => {
                            addStatus('approved');
                        }}>Accept</Button>
                        <Button variant='danger' onClick={async (e) => {
                            addStatus('rejected')
                        }}>Reject</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>)
}

export default Approvals