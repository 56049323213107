import React from 'react'
import footerLogo from "../../assets/images/footer-logo.png"
import "./Footer.scss";

const Footer = () => {
    return (
        <div>
            <section className='footer-section py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-5 col-md-5 col-lg-3'>
                            <p>Powered by</p>
                            <img src={footerLogo} width={160} className="img-fluid mb-3" alt="office" />
                            <div className="">
                                Knowledge City Rai Durg,<br />
                                Hyderabad, Telangana, India.<br />
                                Tel: +91 40 4651 5454<br />
                            </div>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-3'>
                            <a href="#" className="link-light d-block">Initiatives</a>
                            <a href="#" className="link-light d-block">Financial Services</a>
                            <a href="#" className="link-light d-block">Employee &#x26; Employer Financial Wellness</a>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-3'>
                            <a href="#" className="link-light d-block">Initiatives</a>
                            <a href="#" className="link-light d-block">Financial Services</a>
                            <a href="#" className="link-light d-block">Employee &#x26; Employer Financial Wellness</a>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-3'>
                            <a href="#" className="link-light d-block">Contact Us</a>
                            <a href="#" className="link-light d-block">About Us</a>
                            <a href="#" className="link-light d-block">Terms</a>
                            <a href="#" className="link-light d-block">Packages</a>
                            <a href="#" className="link-light d-block">FAQ</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer