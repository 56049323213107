import { useState, useEffect } from "react";
import { URLS } from "../../helpers/URLS";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useAxios from "../../helpers/Hooks/useAxios";
import EmployeeTable from "../Common/EmployeeTable";
import ProjectsTable from "../Common/ProjectsTable";
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import { TextField,InputAdornment} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const WorkReport = () => {

    const { fetchData } = useAxios();

    const [isLoad, setIsLoad] = useState(false);

    const [loadSkeleton, setLoadSkeleton] = useState(true);

    const [search,setSearch]=useState('');

    const [searchResult,setSearchResult]=useState([]);

    const [workTeam, setWorkTeam] = useState({
        projectList: [],
        Team: []
    })

    useEffect(()=>
    {
         search.length===0 ? setSearchResult(workTeam)
         : setSearchResult(workTeam.filter(
            emp=>emp.employeeName.toLowerCase().includes(search.toLowerCase()) ||
                emp.emailID.toLowerCase().includes(search.toLowerCase)                
        ))
       

    },[search])

    useEffect(() => {
        getWorkTeamsList();
    }, [isLoad]);

    const getWorkTeamsList = async () => {
        setLoadSkeleton(true);
        const resp = await fetchData({ url: URLS.getTeamList, method: 'get' });        
        if (resp.status === 200) {
            setWorkTeam(resp.data[0].teamList);
            setSearchResult(resp.data[0].teamList);
            setIsLoad(true);
            setLoadSkeleton(false);
        }
    }

    return (
        <div className=''>
            <div className="d-flex">
            <div className="text-start"><h4 className="title mb-3">Team Work Reports</h4></div>
            <TextField label={'Search Employee'} sx={{ ml: 1,mb:2 }} style={{ backgroundColor: 'white' }} 
            onChange={(e) => setSearch(e.target.value)}
            size='small'
            className="ms-auto"
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          >
            </TextField>
            </div>



            {
                // loadSkeleton ? <SkeletonLoader /> : (
                //     workTeam.Team.length > 0 ?                       
                //         isLoad && (
                //                 <Tabs
                //                     defaultActiveKey={workTeam.Team.length > 0 ? "Team" : "Project"}
                //                     id="uncontrolled-tab-example"
                //                     className="fw-bold"
                                    
                //                 >
                //                     {/* {workTeam.Team.length !== 0 && (<Tab eventKey="Team" title="Team">
                //                         <EmployeeTable employeesList={workTeam.Team} />
                //                     </Tab>)} */}
                                    
                //                     {/* {workTeam.projectList.length !== 0 && (<Tab eventKey="Project" title="Project">
                //                         <ProjectsTable projectList={workTeam.projectList} />
                //                         {/* projectList */}
                //                     {/* </Tab>)}  */}
                //                     <Tab eventKey="Team" title="Team">
                //                         {
                //                             workTeam.Team.length!==0?<EmployeeTable employeesList={workTeam.Team} />:<NoData/>
                //                         }
                //                     </Tab>
                //                     <Tab eventKey="Project" title="Project">
                //                         {
                //                             workTeam.projectList.length!==0?<ProjectsTable projectList={workTeam.projectList} />:<NoData/>
                //                         }
                //                     </Tab>
                //                 </Tabs>)
                       
                //         : <NoData />
                // )

                    workTeam.length > 0 ? <EmployeeTable employeesList={searchResult} />:<NoData/>




            }
           
        </div>
    );
}

export default WorkReport;