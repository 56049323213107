import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decrypt } from "../../helpers/utilities";
import { URLS } from "../../helpers/URLS";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Grid, Button } from "@mui/material";
import useAxios from "../../helpers/Hooks/useAxios";
import Accordion from 'react-bootstrap/Accordion';
import NoData from "../../loaders/NoData";
import SkeletonLoader from "../../loaders/Skeleton";
import moment from "moment";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";
import { toast } from 'react-toastify';

const ViewStatus = () => {

    const { fetchData } = useAxios();

    const { parameter, value } = useParams();

    const [isProject, setIsProject] = useState(false);

    const [isNodata, setNodata] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);

    const [searchParameters, setSearchParameters] = useState({
        startDate: new Date(moment().subtract(10, 'days').format('YYYY-MM-DD')),
        endDate: new Date()
    });

    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        if (parameter && value) {
            getDailyStatus()
        }
    }, [parameter, value]);

    const getDailyStatus = async () => {
        try {
            const paramName = decrypt(parameter);
            const paramValue = decrypt(value);
            if (paramName === "projectID") {
                setIsProject(true)
            }
            const resp = await fetchData({ url: `${URLS.getDailyStatus}?startDate=${searchParameters.startDate}&endDate=${searchParameters.endDate}&${paramName}=${paramValue}`, method: 'get' });
            if (resp.status === 200) {
                setStatusList(resp.data)
                setNodata(true)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getDailyStatusdownload = async () => {
        try {
            const paramName = decrypt(parameter);
            const paramValue = decrypt(value);          
            const resp = await fetchData({ url: `${URLS.getDailyStatusdownload}?startDate=${searchParameters.startDate}&endDate=${searchParameters.endDate}&${paramName}=${paramValue}`, method: 'get' });
            if (resp.status === 200) {
                window.open(`${URLS.fileDownloadServer}${resp.data.location}/${resp.data.fileName}`)
                // setStatusList(resp.data)
                setNodata(true)
            } 
            else 
            {
                toast.error(resp.message, 'error')
            }
        } catch (error) {
            console.log(error);
        }
    }


    

    const getPageNo = (data) => {
        setPageNumber(data)
    }

    const countPerPage = 10;

    return (
        <div className='content'>
            <h4 className='title'>Team Report</h4>
            <div className="shadow-sm p-2">
                <div className='resp_status_big'>
                    <div className="mx-3 mt-2">
                        <Grid container className="pb-3 align-items-center">
                            <Grid item xs={3} sx={{ padding: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={new Date(searchParameters.startDate)}
                                        maxDate={new Date()}
                                        onChange={(date) => { setSearchParameters({ ...searchParameters, startDate: date }) }}
                                        renderInput={(params) => <TextField {...params} fullWidth required
                                        />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3} sx={{ padding: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={new Date(searchParameters.endDate)}
                                        minDate={new Date(searchParameters.startDate)}
                                        maxDate={new Date()}
                                        onChange={(date) => setSearchParameters({ ...searchParameters, endDate: date })}
                                        renderInput={(params) => <TextField {...params} fullWidth required
                                        />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} >
                                <Button variant="contained" onClick={(e) => {
                                    getDailyStatus();
                                }}>Search</Button>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Button
                                 disabled={statusList.length?false:true}
                                variant="contained" onClick={(e) => {
                                    getDailyStatusdownload();
                                }}>Download</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className='resp_status_small'>
                    <div className="d-flex justify-content-center">
                        <div className="">
                            <div className='mb-2'>
                                <Grid item xs={6} sx={{ padding: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Start Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={new Date(searchParameters.startDate)}
                                            maxDate={new Date()}
                                            onChange={(date) => { setSearchParameters({ ...searchParameters, startDate: date }) }}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </div>
                            <div className=''>
                                <Grid item xs={6} sx={{ padding: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="End Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={new Date(searchParameters.endDate)}
                                            minDate={new Date(searchParameters.startDate)}
                                            maxDate={new Date()}
                                            onChange={(date) => setSearchParameters({ ...searchParameters, endDate: date })}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className='m-2'>
                        <div className='d-flex justify-content-around'>
                            <Button variant="contained" onClick={(e) => {
                                getDailyStatus();
                            }}>Search</Button>
                        </div>
                    </div>
                </div>
                {statusList.length > 0 ?
                    <Accordion defaultActiveKey="0" className="m-3">
                        {statusList.slice((pageNumber - 1) * countPerPage,
                            pageNumber * countPerPage).map((row, index) =>
                                <Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>{moment(row.date).format('DD MMMM YYYY')}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        {isProject && <th scope="col">Employee Name</th>}
                                                        {isProject && <th scope="col">Employee Code</th>}
                                                        <th scope="col">Project Name</th>
                                                        <th scope="col">Activity Name</th>
                                                        <th scope="col">Hours</th>
                                                        <th scope="col">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.data.map((s, subIndex) =>
                                                        <tr key={subIndex}>
                                                            <th scope="row">{subIndex + 1}</th>
                                                            {isProject && <td>{s.employee.employeeName}</td>}
                                                            {isProject && <td>{s.employee.employeeCode}</td>}
                                                            <td>{s.project.projectName}</td>
                                                            <td>{s.Activity.activityName}</td>
                                                            <td>{s.hours}</td>
                                                            <td>{s.description}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        {statusList && statusList.length > 0 ? <PaginationGlobal
                            count={Math.ceil(statusList.length / countPerPage)}
                            pageNumber={pageNumber} func={getPageNo} /> : ""}
                    </Accordion>
                    : (isNodata ? <NoData /> : <SkeletonLoader />)
                }
            </div>
        </div>
    );
}

export default ViewStatus;