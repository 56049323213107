import React from 'react'
import NabBar from '../../components/Navbar/NabBar'
import Banner from '../../components/Banner/Banner'
import Footer from '../../components/Footer/Footer'
import HomeContent from '../../components/HomeContent/HomeContent'

const Home = () => {
  return (
    <div >
      <NabBar />
      <Banner />
      <HomeContent />
      <Footer />
    </div>
  )
}

export default Home