import "../../Login.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import NabBar from "../../components/Navbar/NabBar";
import Footer from "../../components/Footer/Footer";
import Loginpic from "../../assets/images/login.png"

const AccountActivate = () => {

    const navigate = useNavigate();
    const { fetchData } = useAxios();

    const [loginPayload, setLoginPayload] = useState({
        email: "",
    });

    const [vericationDetails, setVerificationDetails] = useState({
        isActive: false,
        otp: "",
        password: "",
        email: "",
        re_password: ""
    })

    // useEffect(() => {
    //     checkIsAuthenticated();
    // }, [])

    // const checkIsAuthenticated = async () => {
    //     const resp = await fetchData({ url: URLS.auth, method: "get" });
    //     if (resp.status === 200) {
    //         navigate("/dashBoard");
    //     }
    // }

    const handleChange = (e) => {
        setLoginPayload({
            ...loginPayload,
            [e.target.name]: e.target.value,
        });
    };
    const accounhandleChange = (e) => {
        setVerificationDetails({
            ...vericationDetails,
            [e.target.name]: e.target.value,
        });
    };
    
    const submitLogin = async (e) => {
        if (!e) {
            return null;
        }
        e.preventDefault();
        if (!vericationDetails.isActive) {
            if(!loginPayload.email)
            {
                toast.error("Email is required")
                return;
            }

            const resp = await fetchData({
                url: URLS.activeAccountOtpGenerate,
                method: "post",
                body: loginPayload,
            });
            if (resp.status === 200) {
                setVerificationDetails({
                    ...vericationDetails,
                    email: loginPayload.email,
                    isActive: true
                })
                toast.success(resp.message)
            }
            else {
                toast.error(resp.message)
            }
        } else {
            if(!vericationDetails.otp)
            {
                toast.error("Otp is required")
                return;
            }

            if(!vericationDetails.password)
            {
                toast.error("password is required")
                return;
            }

            if(vericationDetails.password !== vericationDetails.re_password)
            {
                toast.error("password  does not mached re-enterpassword")
                return;
            }
            const resp = await fetchData({
                url: URLS.activeAccount,
                method: "post",
                body: vericationDetails,
            });
            if (resp.status === 200) {
          
                toast.success(resp.message)
                navigate("/login");
            }
            else {
                toast.error(resp.message)
            }

        }
    }


    return (
        <div>
            <NabBar />
            <form onSubmit={submitLogin}>
                <div id="login">
                    <div className="container p-3">
                        <div className="row d-flex justify-content-center p-5">
                            <div className="profile col-4 d-flex align-items-center justify-content-center">
                                <img src={Loginpic} className="profile-image" />
                            </div>
                            {vericationDetails && !vericationDetails.isActive && (<div className="col-md-4 col-sm-12 login-details p-4">
                                <center className="title mb-3">Activate Account</center>
                                <input type="text" value={loginPayload.email} onChange={handleChange} name="email" placeholder="email@motivitylabs.com" className="mb-3" />
                                <button type="submit" className="mb-3 button_login">submit</button>
                            </div>)}
                            {vericationDetails && vericationDetails.isActive && (<div className="col-md-4 col-sm-12 login-details p-4">
                                <center className="title mb-3">Activate Account</center>
                                <input autoComplete="off" onChange={accounhandleChange} type="text" value={vericationDetails.otp} name="otp" placeholder="123456" className="mb-3" />
                                <input autoComplete="off" onChange={accounhandleChange} type="password" name="password" value={vericationDetails.password} placeholder="password" className="mb-3" />
                                <input autoComplete="off" onChange={accounhandleChange} type="password" name="re_password" value={vericationDetails.re_password} placeholder="Re-password" className="mb-3" />
                                <button type="submit" className="mb-3 button_login">submit</button>
                            </div>)}
                        </div>
                    </div>
                </div>
            </form>

            <Footer />
        </div>
    );
};

export default AccountActivate;

