import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { Button, MenuItem, TextField } from '@mui/material';
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddCandidate from './AddCandidate';
import NoData from '../../loaders/NoData';
import CandidateCard from './CandidateCard';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BasicTabs = () => {

    const [value, setValue] = React.useState(0);

    const [pool, setPool] = useState([])

    const [addCandidateModal, setAddCandidateModal] = useState(false)

    const [scheduledData, setScheduledData] = useState([]);

    const [selectedCandidates, setSelectedCandidates] = useState([]);

    const [rejectedCandidates, setRejectedCandidates] = useState([]);

    const [selectedCandidateForEdit, setSelectedCandidateForEdit] = useState({});

    const [isLoad, setIsLoad] = useState(false);

    const [jobJDS, setjobJDS] = useState([]);

    const [jobId, setJobId] = useState('All')

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { fetchData } = useAxios();

    useEffect(() => {
        getAllCandidates();
        GetJobJds();

    }, [])

    useEffect(() => {
        getScheduledCandidates();
        getRejectedCandidates();
    }, [jobId])

    const GetJobJds = async () => {
        const { data } = await fetchData({ url: URLS.getJobs, method: 'get' })
        setjobJDS(data);
    }

    const getAllCandidates = async () => {
        setIsLoad(true);
        let response = await fetchData({ url: URLS.getAllCandidates, method: 'get' })
        if (response.status === 200) {
            setPool(response.data);
            setIsLoad(false)
        }
        else {
            toast.error(response.message);
        }
    }

    const getScheduledCandidates = async () => {
        setIsLoad(true);
        let response = jobId === 'All' ?
            await fetchData({ url: URLS.getScheduledCandidates, method: 'get' }) :
            await fetchData({ url: URLS.getScheduledCandidates + '?jobID=' + jobId, method: 'get' })
        if (response.status === 200) {
            setScheduledData(response.data);
            setIsLoad(false);
        }
        else {
            toast.error(response.message);
        }
    }

    const getSelectedCandidates = async () => {
        setIsLoad(true);
        let response = jobId === 'All' ?
            await fetchData({ url: URLS.getSelectedCandidates, method: 'get' }) :
            await fetchData({ url: URLS.getSelectedCandidates + '?jobID=' + jobId, method: 'get' })
        if (response.status === 200) {
            setSelectedCandidates(response.data);
            setIsLoad(false)
        }
        else {
            toast.error(response.message);
        }
    }

    const getRejectedCandidates = async () => {
        setIsLoad(true);
        let response = jobId === 'All' ?
            await fetchData({ url: URLS.getRejectedCandidates, method: 'get' }) :
            await fetchData({ url: URLS.getRejectedCandidates + '?jobID=' + jobId, method: 'get' })
        if (response.status === 200) {
            setRejectedCandidates(response.data);
            setIsLoad(false)
        }
        else {
            toast.error(response.message);
        }
    }

    return (
        <div>
            <div className='row'>
                <Box sx={{ width: '100%' }} position={'relative'}>
                    <div className='row'>
                        <div className='col-md-8'>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Pool" {...a11yProps(0)} onClick={getAllCandidates} />
                                    <Tab label="Scheduled" {...a11yProps(1)} onClick={getScheduledCandidates} />
                                    <Tab label="Selected" {...a11yProps(2)} onClick={getSelectedCandidates} />
                                    <Tab label="Rejected" {...a11yProps(2)} onClick={getRejectedCandidates} />
                                </Tabs>
                            </Box>
                        </div>
                        <div className='col-md-2'>
                            <TextField select label='select Job' size='small' fullWidth sx={{ backgroundColor: 'white' }}
                                value={jobId}
                                onChange={(e) => setJobId(e.target.value)}
                            >
                                <MenuItem value='All'>
                                    All
                                </MenuItem>
                                {
                                    jobJDS.map((job,i) =>
                                        <MenuItem value={job.jobID} key={i}>{job.jobTitle}</MenuItem>
                                    )
                                }
                            </TextField>
                        </div>
                        <div className='col-md-2'>
                            <Box className='float-lg-end float-md-end float-sm-none' >
                                <Button variant="contained" color={'primary'} onClick={() => { setAddCandidateModal(true); setSelectedCandidateForEdit({}) }} >Add Candidate</Button>
                            </Box>
                        </div>
                    </div>
                    <TabPanel value={value} index={0} >
                        {pool.length > 0 ? <CandidateCard pool={pool} showEditBtn cardClass="candicate-card-warning" setAddCandidateModal={setAddCandidateModal} setSelectedCandidateForEdit={setSelectedCandidateForEdit} /> : (!isLoad ? <NoData /> : "")}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {scheduledData.length > 0 ? <CandidateCard pool={scheduledData} cardClass="candicate-card-info" /> : (!isLoad ? <NoData /> : "")}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {selectedCandidates.length > 0 ? <CandidateCard pool={selectedCandidates} cardClass="candicate-card-success" /> : (!isLoad ? <NoData /> : "")}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {rejectedCandidates.length > 0 ? <CandidateCard pool={rejectedCandidates} cardClass="candicate-card-error" /> : (!isLoad ? <NoData /> : "")}
                    </TabPanel>
                    <AddCandidate show={addCandidateModal} onHide={setAddCandidateModal} reloadContent={getAllCandidates} selectedCandidateForEdit={selectedCandidateForEdit} />
                </Box>
            </div>
        </div>
    );
}

export default BasicTabs