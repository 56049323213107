import React from 'react'
import NabBar from '../../components/Navbar/NabBar'
import './About.scss';

const About = () => {
  return (
    <div className=''>
      <NabBar />
      <div className='container'>
        <div className="mb-3 mt-4">
          <label for="exampleFormControlInput1" className="form-label">Email address</label>
          <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlTextarea1" className="form-label">Example textarea</label>
          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="a" />
          <label class="form-check-label" for="a">
            Default checkbox
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
          <label class="form-check-label" for="flexCheckChecked">
            Checked checkbox
          </label>
        </div>
        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
        <div class="form-check-radio">
          <div class="form-check">
            <input type="radio" id="radio02-01" name="demo02" />
            <label for="radio02-01">Radio</label>
          </div>
          <div class="form-check">
            <input type="radio" id="radio02-02" name="demo02" /><label for="radio02-02">Radio</label>
          </div>
        </div>
      </div>
    </div>
  )
}
export default About
