import React, { createContext, useState } from 'react';

const LoaderContext = createContext({
    setIsLoader: (con) => { }
});

export const LoaderProvider = ({ children }) => {
   
    const [isLoader, setIsLoader] = useState(false);

    return <LoaderContext.Provider value={{
        setIsLoader: setIsLoader
    }} >
        {isLoader && (<div id="backdrop">
            <div className="text-center loading">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>)}
        {children}
    </LoaderContext.Provider>
}

export default LoaderContext;