import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { dateFormat } from "../../helpers/utilities";
import { encrypt } from "../../helpers/utilities";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";

const ProjectsTable = ({ projectList }) => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);

  const [show, setShow] = useState(false);

  const [teamMem, setTeamMem] = useState({
    team: [],
    projectName: ""
  });

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const getPageNo = (data) => {
    setPageNumber(data)
  }
  const countPerPage = 10;

  return (
    <div className="table-responsive tab-styles">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">project id</th>
            <th scope="col">project name</th>
            <th scope="col">start date</th>
            <th scope="col">View</th>
            <th scope="col">action</th>
          </tr>
        </thead>
        <tbody>
          {/* {projectList.map((project) => { */}
          {projectList && projectList.slice((pageNumber-1)*countPerPage,pageNumber*countPerPage).map((project)=>{
            return (
              
              <tr key={project.projectID}>
                <td scope="row">{project.projectID}</td>
                <td>{project.projectTeam[0].projectName}</td>
                <td>{dateFormat(project.projectStartDate)}</td>
                <td>
                  <button
                    disabled={
                      !project.employeeList.some((obj) =>
                        obj.hasOwnProperty("employeeName")
                      )
                    }
                    type="button"
                    className="btn btn-outline-success me-2"
                    onClick={() => {
                      handleShow();
                      setTeamMem({
                        ...teamMem,
                        team: project.employeeList,
                        projectName: project.projectName,
                      });
                    }}
                  >
                    Team({project.employeeList.some((obj) =>
                      obj.hasOwnProperty("employeeName")
                    )
                      ? project.employeeList.length
                      : "0"}
                    )
                  </button>
                </td>
                <td>
                  <Tooltip title="View Projects" placement="top" arrow>
                    <button className="btn btn-secondary p-0">
                      <VisibilityIcon
                        className="p-1 text-white"
                        onClick={() => {
                          navigate(
                            `/report-view/${encrypt("projectID")}/${encrypt(
                              project.projectID
                            )}`
                          );
                        }}
                      />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {
        projectList && projectList.length > 0 ?
          <PaginationGlobal count={Math.ceil(projectList.length / countPerPage)}
            pageNumber={pageNumber} func={getPageNo} /> : ""
      }
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><p>{teamMem.projectName}</p> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fw-bold">Project Team Members</p>
          {teamMem.team.length === 0 ? <p className="fw-bold">Nobody Assigned To This Project</p> :
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Emp. ID</th>
                    <th>Name</th>
                    <th>Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {teamMem.team.map((each,index) => <tr key={index}>
                    <td>{each.employeeCode}</td>
                    <td>{each.employeeName}</td>
                    <td>{each.designation}</td>
                  </tr>)}
                </tbody>
              </table>
            </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjectsTable;