import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { decrypt } from "../../helpers/utilities";
import { downloadLink } from '../../helpers/constFIles';
import { URLS } from "../../helpers/URLS";
import './CandidateDetails.scss';
import Select from "react-select"
import DatePicker from "react-datepicker";
import useAxios from "../../helpers/Hooks/useAxios";
import ScheduleInterview from "./ScheduleInterview";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';

const CandidateDetails = () => {

    const { candidateID } = useParams();

    const { fetchData } = useAxios();

    const [isStatusModel, setIsStatusModel] = useState(false);

    const [viewResults, setViewResults] = useState({})

    const [employeeList, setEmployeeList] = useState([]);

    const [interViewProcess, setInterViewProcess] = useState([]);

    const [showDetail, setShowDetail] = useState(false);

    const [rounds, setRounds] = useState(true)

    const [show, setShow] = useState(false)

    const [candidate, setCandidate] = useState({
        jobPosition: {
            "createdDate": "",
            "employeeID": "",
            "experienceFrom": "",
            "interViewType": "",
            "experienceTo": "",
            "isActive": false,
            "jobJDID": "",
            "jobTitle": "",
            "requirements": "",
            "skills": "",
            "_id": ""
        },
        interViewList: [],
        candidate: {
            "AADHAR": "",
            "PAN": "",
            "candidateID": "",
            "comments": [],
            "currentCTC": "",
            "email": "",
            "employeeID": "",
            "expectedCTC": "",
            "experience": "",
            "isActive": "",
            "isSelected": "",
            "jobJD": "",
            "name": "",
            "noticePeroid": null,
            "phone": "",
            "resumeUrl": "",
            "skills": "",
            "willJoinEarly": null
        }
    })

    useEffect(() => {
        if (candidateID) {
            getDetails();
            getEmployeeList()
        }
    }, []);

    const getDetails = async () => {
        try {
            const ID = decrypt(candidateID);
            const resp = await fetchData({ url: URLS.getParticularCandidate(ID), method: 'get' });
            if (resp.status === 200) {
                setInterViewProcess([...resp.data.interViewList])
                if (resp.data.interViewList && !resp.data.interViewList.length) {
                    setCandidate({
                        ...resp.data,
                        interViewList: [
                            {
                                interViewID: "",
                                candidateID: "",
                                roundType: "",
                                startTime: new Date(),
                                endTime: new Date(),
                                employeeID: "",
                                status: "",
                                review: [],
                                comments: ""
                            }
                        ]
                    })
                } else {
                    setCandidate(resp.data);
                }
            }
        } catch (error) { }
    }

    const getEmployeeList = async () => {
        const resp = await fetchData({ url: `${URLS.getEmpoyees}`, method: 'get' });
        if (resp.status === 200) {
            const list = resp.data.map(x => {
                return {
                    ...x,
                    label: `${x.employeeName} - ${x.employeeCode}`
                }
            })
            setEmployeeList(list)
        }
    }

    const timeInMin = (time1, time2) => {
        var startTime = moment(moment(time1), "HH:mm a");
        var endTime = moment(moment(time2), "HH:mm a");
        var duration = moment.duration(endTime.diff(startTime))
        var minutes = parseInt(duration.asMinutes());
        return minutes + " min"
    };

    const roundOptions = [
        { value: 'Technical', label: 'Technical' },
        { value: 'Hr', label: 'Hr' },
        { value: 'Written', label: 'Written' }
    ]

    const interviewoptions = [
        { value: 'Face-to-Face', label: 'Face to Face' },
        { value: 'Virtual', label: 'Virtual' }
    ]

    const interviewSchedule = () => {
        setShowDetail(!showDetail);
    }

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const modelHandle = () => {
        setIsStatusModel(!isStatusModel);
    };

    const color = (status) => {
        if (status === 'Rejected') {
            return 'text-danger'
        }
        if (status === 'Selected') {
            return 'text-success'
        }
        if (status === 'Hold') {
            return 'text-warning'
        }
        return 'text-primary'
    }

    const colorRound = (status) => {
        if (status === 'Rejected') {
            return 'stepper_circle_4 text-white'
        }
        if (status === 'Selected') {
            return 'stepper_circle_1 text-white'
        }
        if (status === 'Hold') {
            return 'stepper_circle_2 text-white'
        }
        return 'stepper_circle_3 text-white'
    }

    const colorButton = (status) => {
        if (status === 'Rejected') {
            return 'btn btn-danger mb-3'
        }
        if (status === 'Selected') {
            return 'btn btn-success mb-3'
        }
        if (status === 'Hold') {
            return 'btn btn-warning mb-3'
        }
        return 'btn btn-secondary mb-3'
    }

    return (
        <div className="">
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-sm-flex">
                            <div className="d-flex">
                                <div className="bg-secondary me-3" style={{ width: '60px', height: '60px', borderRadius: '50%' }}>
                                    <img className="w-100 rounded-circle" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" />
                                </div>
                                <div>
                                    <p className="mb-2 fw-bold d-block">Candidate Details</p>
                                    <div className="d-block d-md-none">
                                        <a href={downloadLink + candidate.candidate.resumeUrl} className="d-flex align-items-center btn btn-primary me-2">
                                            <VisibilityOutlinedIcon className="text-white" />
                                            <p className="m-0 ps-2">View Resume</p>
                                        </a>
                                    </div>
                                    <div className="d-sm-flex mt-2">
                                        <div className="d-flex align-items-center me-3">
                                            <BadgeOutlinedIcon className="text-secondary" />
                                            <p className="m-0 ps-1">{candidate.candidate.candidateID}</p>
                                        </div>
                                        <div className="d-flex align-items-center me-3">
                                            <PermIdentityIcon className="text-secondary" />
                                            <p className="m-0 ps-1">{candidate.candidate.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center me-3">
                                            <AlternateEmailIcon className="text-secondary" />
                                            <p className="m-0 ps-1">{candidate.candidate.email}</p>
                                        </div>
                                        <div className="d-flex align-items-center me-3">
                                            <PhoneInTalkOutlinedIcon className="text-secondary" />
                                            <p className="m-0 ps-1">{candidate.candidate.phone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="d-md-block d-none">
                                <a href={downloadLink + candidate.candidate.resumeUrl} className="d-flex align-items-center btn btn-primary me-2">
                                    <VisibilityOutlinedIcon className="text-white" />
                                    <p className="m-0 ps-2">View Resume</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-lg-3">
                            <div className="d-flex mb-2">
                                <p className="fw-bold me-2">Current CTC:</p>
                                <p>{candidate.candidate.currentCTC} LPA</p>
                            </div>
                            <div className="d-flex mb-2">
                                <p className="fw-bold me-2">Skills:</p>
                                <p>{candidate.candidate.skills}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-3">
                            <div className="d-flex mb-2">
                                <p className="fw-bold me-2">Experience:</p>
                                <p>{candidate.candidate.experience ? candidate.candidate.experience + ' Years' : 'Fresher'}</p>
                            </div>
                            <div className="d-flex">
                                <p className="fw-bold me-2">Expected CTC:</p>
                                <p>{candidate.candidate.expectedCTC} LPA</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-3">
                            <div className="d-flex mb-2">
                                <p className="fw-bold me-2">PAN/AADHAR:</p>
                                <p>{candidate.candidate.AADHAR || candidate.candidate.PAN}</p>
                            </div>
                            <div className="d-flex">
                                <p className="fw-bold me-2">Will Join Early:</p>
                                <p>{candidate.candidate.willJoinEarly} Days</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-3">
                            <div className="d-flex mb-2">
                                <p className="fw-bold me-2 mb-1">Notice Peroid:</p>
                                <p className="mb-2">{candidate.candidate.noticePeroid} Days</p>
                            </div>
                            <div className="d-flex mb-2">
                                <p className="fw-bold me-2">Position:</p>
                                <p className="mb-2">{candidate.jobPosition.jobTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary mt-4 mb-4 me-4"
                        onClick={(e) => {
                            modelHandle();
                        }}>
                        Assignee
                    </button>
                </div>
                <div className="card-body">
                    {interViewProcess.map((row, index) => (
                        <div className="row">
                            <div className="">
                                {rounds &&
                                    <div className="assign_big ms-2">
                                        <div key={index} className={`stepper_line_1 ms-4`}>
                                            <div>
                                                <div className={colorRound(row.status)}>{row.round}</div>
                                                <p className="fw-bold">Round {row.round} / {row.roundType} for {candidate.jobPosition.jobTitle} </p>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="mb-3 row">
                                                            <div className="d-flex mt-4">
                                                                <p className="">Interviewer:</p>
                                                                <p>{row.employee.employeeName} ({row.employee.employeeCode})</p>
                                                            </div>
                                                            <div className="d-flex mt-4">
                                                                <p className="">Schedule:</p>
                                                                <div className="d-flex">
                                                                    <p className="me-2">{moment(row.startTime).format('MMMM Do YYYY')}</p>
                                                                    <p className="text-primary me-2">{moment(row.startTime).format('LT')} - {moment(row.endTime).format('LT')}</p>
                                                                    <p className="text-secondary">{timeInMin(row.startTime, row.endTime)}</p>
                                                                </div>
                                                            </div>
                                                            <div className=" d-flex mt-4">
                                                                <p className="mb-0 me-1">Status:</p>
                                                                <p className={color(row.status)}>{row.status}</p>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <button
                                                                disabled={!row.status}
                                                                className={colorButton(row.status)} onClick={() => {
                                                                    row.selectedIndex = index
                                                                    setViewResults(row)
                                                                    setShow(true)
                                                                }
                                                                }><p className="text-white m-0">View Results</p></button>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        {show && index === viewResults.selectedIndex &&
                                                            <div class="" style={{ marginTop: '-20px' }} key={index}>
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <h5 style={{ fontWeight: '600' }}>Performance score</h5>
                                                                    </div>
                                                                    <div className="">
                                                                        <button className="btn p-0" onClick={() => { setShow(false) }} >
                                                                            <CloseIcon className="p-1" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {viewResults?.review?.map((row, sub_index) =>
                                                                    <div key={sub_index} class="border p-1 mt-2 rounded">
                                                                        <div className="p-1 px-4 d-flex align-items-center rounded row">
                                                                            <span className="d-block text-success col-5">{row.label}</span>
                                                                            <span className="text-success col-2">{row.rating}</span>
                                                                            <span className="col-5">{row.value}</span>
                                                                        </div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <Modal
                size="lg"
                show={isStatusModel}
                onHide={modelHandle}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Interview Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!showDetail && (<div className="work-report-section bg-white">
                        <div className="d-flex justify-content-end">
                            {!candidate.interViewList.length || (candidate.interViewList[candidate.interViewList.length - 1].status === 'Selected') && (<button className="btn btn-primary mt-1 mb-2"
                                onClick={() => {
                                    const temp = { ...candidate }
                                    temp.interViewList.push({
                                        interViewID: "",
                                        candidateID: "",
                                        roundType: "",
                                        startTime: new Date(),
                                        endTime: new Date(),
                                        employeeID: "",
                                        interViewType: "",
                                        status: "",
                                        review: [],
                                        comments: ""
                                    })
                                    setCandidate(temp);
                                }} >
                                Add Round
                            </button>)}
                        </div>
                        {candidate.interViewList.map((row, index) => <div key={index} className="row">
                            <div className="col-md-11">
                                <div className='mb-3 text-start mt-4'>
                                    <p><b>Round {index + 1} :-</b></p>
                                </div>
                            </div>
                            <div className="col-md-1 ">
                                <div className='mb-3 mt-4'>
                                    {index !== 0 && !row.status && (<button className='round-remove-btn'>-</button>)}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        Round Type
                                    </label>
                                    <Select
                                        isDisabled={row.status === 'Selected'}
                                        value={roundOptions.find(x => x.label === row.roundType)}
                                        onChange={(value) => {
                                            const tRow = { ...row }
                                            tRow.roundType = value.label;
                                            const temp = { ...candidate }
                                            temp.interViewList[index] = tRow;
                                            setCandidate(temp);
                                        }}
                                        options={roundOptions} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        Employee
                                    </label>
                                    <Select
                                        value={employeeList.find(x => x.employeeCode === row.employeeID)}
                                        isDisabled={row.status === 'Selected'}
                                        onChange={(value) => {
                                            const tRow = { ...row }
                                            tRow.employeeID = value.employeeCode;
                                            const temp = { ...candidate }
                                            temp.interViewList[index] = tRow;
                                            setCandidate(temp);
                                        }}
                                        options={employeeList} />
                                </div>
                            </div>
                            <div class="col-md-6 mt-2 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        From
                                    </label>
                                    <DatePicker
                                        className="view-date form-control candidate-interview-time"
                                        selected={new Date(row.startTime)}
                                        disabled={row.status}
                                        minDate={moment().toDate()}
                                        filterTime={filterPassedTime}
                                        onChange={(date) => {
                                            const tRow = { ...row }
                                            tRow.startTime = date;
                                            const temp = { ...candidate }
                                            temp.interViewList[index] = tRow;
                                            setCandidate(temp);
                                        }}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6 mt-2 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        To
                                    </label>
                                    <DatePicker
                                        className="view-date form-control candidate-interview-time"
                                        selected={new Date(row.endTime)}
                                        disabled={row.status}
                                        filterTime={filterPassedTime}
                                        minDate={moment().toDate()}
                                        onChange={(date) => {
                                            const tRow = { ...row }
                                            tRow.endTime = date;
                                            const temp = { ...candidate }
                                            temp.interViewList[index] = tRow;
                                            setCandidate(temp);
                                        }}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 mt-2">
                                <div class="form-group col-md-12">
                                    <label for="inputState">Interview Type</label>
                                    <Select class="form-control"
                                        isDisabled={row.status}
                                        value={interviewoptions.find(x => x.label === row.interViewType)}
                                        onChange={(value) => {
                                            const tRow = { ...row }
                                            tRow.interViewType = value.label;
                                            const temp = { ...candidate }
                                            temp.interViewList[index] = tRow;
                                            setCandidate(temp);
                                        }}
                                        options={interviewoptions}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 mt-2">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Comments</label>
                                    <textarea
                                        value={row.comments}
                                        disabled={row.status}
                                        onChange={(e) => {
                                            const tRow = { ...row }
                                            tRow.comments = e.target.value;
                                            const temp = { ...candidate }
                                            temp.interViewList[index] = tRow;
                                            setCandidate(temp);
                                        }}
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </div>)}
                    </div>)}
                    {showDetail && (<ScheduleInterview interviewSchedule={interviewSchedule} />)}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" color="secondary" onClick={modelHandle}>
                        Close
                    </button>
                    <button className="btn btn-primary" color="primary"
                        disabled={candidate.interViewList.length && candidate.interViewList[candidate.interViewList.length - 1].status}
                        onClick={async () => {
                            const interViewList = candidate.interViewList.map((x, index) => {
                                return {
                                    ...x,
                                    round: index + 1,
                                    candidateID: decrypt(candidateID)
                                }
                            })
                            const { status, message } = await fetchData({
                                url: URLS.schedule, method: 'post', body: {
                                    interViewList: interViewList
                                }
                            })
                            if (status === 200) {
                                toast.success(message);
                                getDetails();
                                modelHandle()
                            }
                            else {
                                toast.error(message, 'error')
                            }
                        }}>
                        Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CandidateDetails