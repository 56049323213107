import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { decrypt } from "../../helpers/utilities";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import { toast } from "react-toastify";
import { downloadLink } from '../../helpers/constFIles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Box, TextField, Grid, FormControl, MenuItem, InputLabel } from "@mui/material"
import Select from "@mui/material/Select"
import ResumeViewModal from "./ResumeViewModal";
import MUILink from '@mui/material/Link';

const Interviewer = () => {
  
  const { candidateID, interViewID, scheduledTo } = useParams();
 
  const { fetchData } = useAxios();
 
  const [resumeShow, setResumeShow] = useState(false);
 
  const [resumeView, setResumeView] = useState({
    filePath: "",
    filetype: "",
    resumeName: ""
  })
  
  const [candidate, setCandidate] = useState({
    jobPosition: {
      createdDate: "",
      employeeID: "",
      experienceFrom: "",
      experienceTo: "",
      isActive: false,
      jobJDID: "",
      jobTitle: "",
      requirements: "",
      skills: "",
      _id: "",
    },
    interView: {
      review: [],
      _id: "",
      candidateID: "",
      comments: "",
      createdDate: "",
      employeeID: "",
      startTime: "",
      endTime: "",
      interViewID: "",
      isActive: null,
      roundType: "",
      status: "",
    },
    candidate: {
      AADHAR: "",
      PAN: "",
      candidateID: "",
      comments: [],
      currentCTC: "",
      email: "",
      employeeID: "",
      expectedCTC: "",
      experience: "",
      isActive: "",
      isSelected: "",
      jobJD: "",
      name: "",
      noticePeroid: null,
      phone: "",
      resumeUrl: "",
      skills: "",
      willJoinEarly: null,
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const CID = decrypt(candidateID);
      const IID = decrypt(interViewID);
      const EID = decrypt(scheduledTo);
      const resp = await fetchData({
        url: URLS.getInterViewData(CID, IID, EID),
        method: "get",
      });
      if (resp.status === 200) {
        let candi = resp.data;
        if (candi.interView.roundType === 'Hr') {
          candi.interView.review = [
            {
              "label": "Communication skill",
              "rating": 3,
              "value": ""
            },
            {
              "label": "comment",
              "rating": 0,
              "value": "Good"
            }
          ]
        }
        setCandidate(candi);
      }
    } catch (err) { }
  };

  const candidateResume = (data) => {
    setResumeView({
      ...resumeView,
      filePath: downloadLink + data.candidate.resumeUrl,
      filetype: data.candidate.resumeUrl.split(".").at(-1),
      resumeName: data.candidate.name
    })
    setResumeShow(true);
  }
  
  const resumeHide = () => {
    setResumeShow(false)
  }

  const roundOptions = [
    { value: "Selected", label: "Selected" },
    { value: "Hold", label: "Hold" },
    { value: "Rejected", label: "Rejected" },
  ];

  return (
    <div className="">
      <div className="mb-3 shadow-sm">
        <div className="card-body p-4">
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex p-1">
                <p className="fw-bold me-2">CandidateID:</p>
                <p >{candidate.candidate.candidateID}</p>
                <div className="px-4">
                  <MUILink onClick={() => {
                    candidateResume(candidate);
                  }
                  }><Button variant="text">Resume</Button></MUILink>
                </div>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Name:</p>
                <p >{candidate.candidate.name}</p>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">EmailID:</p>
                <p >{candidate.candidate.email}</p>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Phone:</p>
                <p >{candidate.candidate.phone}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex w-100 p-1">
                <p className="fw-bold me-2">Position:</p>
                <p >{candidate.jobPosition.jobTitle}</p>
              </div>
              <div className="d-flex w-100 p-1">
                <p className="fw-bold me-2">PAN/AADHAR:</p>
                <p >{candidate.candidate.AADHAR || candidate.candidate.PAN}</p>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Current CTC:</p>
                <p >{candidate.candidate.currentCTC} LPA</p>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Experience:</p>
                <p >
                  {candidate.candidate.experience
                    ? candidate.candidate.experience + " Years"
                    : "Fresher"}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Will Join Early:</p>
                <p >{candidate.candidate.willJoinEarly} Days</p>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Notice Peroid:</p>
                <p >{candidate.candidate.noticePeroid} Days</p>
              </div>
              <div className="d-flex p-1">
                <p className="fw-bold me-2">Expected CTC:</p>
                <p >{candidate.candidate.expectedCTC} LPA</p>
              </div>
              <div className="d-sm-flex p-1">
                <p className="fw-bold me-2">Job Skills:</p>
                <p className="">{candidate.jobPosition.skills}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="d-sm-flex p-1">
              <p className="fw-bold me-2 pt-1">Skills:</p>
              <Stack direction="row" spacing={1}>
                {candidate.candidate.skills.split(",").map((x, index) => (
                  <Chip
                    key={index}
                    label={x}
                    color="primary"
                    variant="outlined" />
                ))}
              </Stack>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid details shadow-sm">
        <h5 className="mx-4">Reviews</h5>
        <div className="row pt-1  px-4">
          {candidate.interView.review.map((x, index) =>
          (<div key={index} className="col-12 col-md-6">
            <div className="form-group ">
              <div className="d-flex justify-content-end mt-3">
                <div>
                  {
                    x.label !== "comment" ?
                      <Rating
                        name="size-small"
                        value={x.rating}
                        size="small"
                        onChange={(event, newValue) => {
                          const temp = { ...candidate };
                          temp.interView.review[index].rating = newValue;
                          setCandidate(temp);
                        }}
                      /> : <div className="mt-4"></div>
                  }
                </div>
              </div>
              <Box xs={6}>
                <TextField fullWidth id="reviews" label={x.label} variant="outlined"
                  value={x.value}
                  type="email"
                  onChange={(e) => {
                    const temp = { ...candidate };
                    temp.interView.review[index].value = e.target.value;
                    setCandidate(temp);
                  }}
                />
              </Box>
            </div>
          </div>
          ))}
          <div className="col-md-6">
            <div className="form-group mt-3">
              <Grid item xs={6} sx={{ pt: 3 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="round-type">Recommendation</InputLabel>
                  <Select labelId="round-type" id="round-type" label="Recommendation"
                    value={candidate.interView.status}
                    name="roundType"
                    onChange={(e) => {
                      const temp = { ...candidate };
                      temp.interView.status = e.target.value;
                      setCandidate(temp);
                    }}
                  >
                    {roundOptions && roundOptions.map((opt, index) =>
                      <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </div>
          </div>
          <div className="col-md-12 text-end">
            <Button
              disabled={candidate.interView.isLock}
              variant="contained"
              className="mt-3"
              onClick={async (e) => {
                const { status, message } = await fetchData({
                  url: URLS.updateReview,
                  method: "post",
                  body: candidate.interView,
                });
                if (status === 200) {
                  toast.success(message);
                  getData();
                } else {
                  toast.error(message, "error");
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <ResumeViewModal
        resumeData={resumeView}
        show={resumeShow}
        onHide={resumeHide}
      />
    </div>
  );
};

export default Interviewer;
