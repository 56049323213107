import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { toast } from 'react-toastify';
import { Grid, TextField, Button } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import Chip from '@mui/material/Chip';
import * as moment from "moment";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";
import './recruiter.css'


const JobJD = () => {

    const { fetchData } = useAxios();

    const [loadSkeleton, setLoadSkeleton] = useState(true);

    const [isStatusModel, setIsStatusModel] = useState(false);

    const [jds, setJds] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);

    const [btnText, setBtnText] = useState("Save");

    const [data, setData] = useState({
        jobID: '',
        jobTitle: '',
        experienceFrom: '',
        experienceTo: '',
        skills: '',
        expiredOn: null,
        requirements: '',
        _id: '',
        numberOfRounds: ''
    })

    const modelHandle = () => {
        if (isStatusModel) {
            resetForm()
        }
        setIsStatusModel(!isStatusModel)
    }

    const handleOnChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const resetForm = () => {
        setData({
            jobID: '',
            jobTitle: '',
            experienceFrom: '',
            experienceTo: '',
            skills: '',
            expiredOn: null,
            requirements: '',
            numberOfRounds: '',
            _id: ''
        })
    }

    const editModal = (updateJob) => {
        setData({
            jobTitle: updateJob.jobTitle,
            experienceFrom: updateJob.experienceFrom,
            experienceTo: updateJob.experienceTo,
            skills: updateJob.skills,
            expiredOn: updateJob.expiredOn,
            requirements: updateJob.requirements,
            numberOfRounds: updateJob.numberOfRounds,
            _id: updateJob._id
        });
    }

    useEffect(() => {
        GetJobJds();
    }, [])

    const GetJobJds = async () => {
        setLoadSkeleton(true);
        const { data } = await fetchData({ url: URLS.getJobs, method: 'get' })
        setJds(data);
        setLoadSkeleton(false)
    }

    const getPageNo = (data) => {
        setPageNumber(data)
    }

    const countPerPage = 10;

    return (
        <div className="">
            <div className='d-flex'>
                <h4 className="title">Jobs</h4>
                <div className="ms-auto">
                    <Button type="button" variant="contained" onClick={(e) => { modelHandle(resetForm()) }}>Add Job</Button>
                </div>
            </div>
            <div className=''>
                <div className="table-responsive bg-wht">
                    {loadSkeleton ? <SkeletonLoader /> :
                        (jds.length > 0 ?
                            <div>
                                <table className="table">
                                    <thead>
                                        <tr style={{ whiteSpace: 'nowrap' }}>
                                            <th scope="col">#</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Experience From</th>
                                            <th scope="col">Experience To</th>
                                            <th scope="col">skills</th>
                                            <th scope="col">Requirements</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">No. of Rounds</th>
                                            <th scope="col">Expires On</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jds && jds.slice((pageNumber - 1) * countPerPage,
                                            pageNumber * countPerPage).map((s, subIndex) =>
                                                <tr key={s._id}>
                                                    <td scope="row">{s.jobID} </td>
                                                    <td>{s.jobTitle}</td>
                                                    <td>{s.experienceFrom}{s.experienceFrom > 1 ? " Years" : " Year"}</td>
                                                    <td>{s.experienceTo} {s.experienceTo > 1 ? " Years" : " Year"}</td>
                                                    <td>{s.skills}</td>
                                                    <td> <div dangerouslySetInnerHTML={{ __html: s.requirements }}></div></td>
                                                    <td>{moment(s.expiredOn).isBefore(new Date(), 'day') ? <Chip label="Inactive" color="error" size="small" /> : <Chip label="Active" color="success" size="small" />}</td>
                                                    <td>{s.numberOfRounds}</td>
                                                    <td>{moment(s.expiredOn).format("DD-MM-YYYY")}</td>
                                                    <td>
                                                        <Tooltip title="Edit Jobs" placement="top">
                                                            <button className="btn btn-primary p-0"><EditIcon onClick={(e => {
                                                                editModal(s)
                                                                modelHandle()
                                                                setBtnText("Update")
                                                            })}
                                                                className="p-1" /></button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>

                                </table>
                                {
                                    jds && jds.length > 0 ? <div> <PaginationGlobal count={Math.ceil(jds.length / countPerPage)}
                                        pageNumber={pageNumber} func={getPageNo} /> </div> : ""
                                }
                            </div>
                            : <NoData />
                        )
                    }
                </div>
                <Modal size="lg" show={isStatusModel} onHide={modelHandle} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{data._id ? "Update" : "Add"} A Job</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='work-report-section bg-white'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField fullWidth label="Job Title" value={data.jobTitle} onChange={handleOnChange} name={'jobTitle'} required size="small" />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField fullWidth label="Experience From" value={data.experienceFrom} onChange={handleOnChange} name={'experienceFrom'} required size="small" />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField fullWidth label="Experience To" value={data.experienceTo} onChange={handleOnChange} name={'experienceTo'} required size="small" />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField fullWidth label="Number of Rounds" value={data.numberOfRounds} onChange={handleOnChange} name={'numberOfRounds'} required size="small" />
                                </Grid>
                                <Grid item xs={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Expire On"
                                            inputFormat="DD/MM/YYYY"
                                            value={data.expiredOn}
                                            minDate={moment().toDate()}
                                            onChange={(date) => setData({ ...data, expiredOn: date })}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth label="Skills" value={data.skills} onChange={handleOnChange} name={'skills'} required size="small" />
                                </Grid>
                                <Grid item xs={12} >
                                    <CKEditor id="requirements" name="requirements"
                                        config={{ placeholder: "Add Job Description" }}
                                        editor={ClassicEditor} data={data.requirements}
                                        onChange={(event, editor) => {
                                            const editordata = editor.getData()
                                            setData({ ...data, requirements: editordata })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="contained" color="inherit" onClick={modelHandle} className="me-3">Close</Button>
                        <Button onClick={async (e) => {
                            const {
                                jobTitle,
                                experienceFrom,
                                experienceTo,
                                skills,
                                expiredOn,
                                requirements,
                                numberOfRounds
                            } = data;
                            let payload = {};
                            if (!data._id) {
                                payload = {
                                    jobTitle,
                                    experienceFrom,
                                    experienceTo,
                                    skills,
                                    expiredOn,
                                    requirements,
                                    numberOfRounds: parseInt(numberOfRounds)
                                }
                            } else {
                                payload = data;
                            }
                            const { status, message } = await fetchData({ url: URLS.addOrUpdateJob, method: 'post', body: payload })
                            if (status === 200) {
                                toast.success(message);
                                GetJobJds();
                                modelHandle()
                            }
                            else {
                                toast.error(message, 'error')
                            }
                        }} variant="contained">
                            {data._id ? "Update" : "Save"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default JobJD;

