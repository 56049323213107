import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const EndProject = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label="End Date"
          inputFormat="YYYY-MM-DD"
          renderInput={(params) => <TextField {...params} className="w-100" />}
        />
      </LocalizationProvider>
      <div className="form-group mt-3">
        <label htmlFor="exampleFormControlTextarea1">Description</label>
        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea></div>
    </div>
  )
}

export default EndProject