import React, { useEffect, useState } from "react";
import { URLS } from "../../helpers/URLS";
import { Box, Button, FormControl, Grid, InputLabel, TextField, Select, MenuItem, IconButton, Tooltip } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAxios from "../../helpers/Hooks/useAxios";

const skillLevels = [
    { label: 'Beginner', value: 'Beginner' },
    { label: 'Intermediate', value: 'Intermediate' },
    { label: 'Advanced', value: 'Advanced' },
    { label: 'Expert', value: 'Expert' },
]

const skillpayload = {
    skills: [],
    desiredSkills: []
}

const newSkillItem = {
    name: "",
    level: "",
    _id: ""
}

const UserProfileSkills = (props) => {

    const { user, reloadProfile } = props;

    const { fetchData } = useAxios();

    const [skillsPayload, setSkillsPayload] = useState(skillpayload)

    const [newSkill, setNewSkill] = useState(newSkillItem)

    const [showSkillsForm, setShowSkillsForm] = useState(false);

    const [newDesiredSkill, setNewDesiredSkill] = useState('')

    const [showDesiredSkillsForm, setShowDesiredSkillsForm] = useState(false);

    useEffect(() => {
        if (user) {
            setSkillsPayload(
                {
                    ...skillsPayload,
                    skills: user.skills ? [...user.skills] : [],
                    desiredSkills: user?.desiredSkills ? [...user.desiredSkills] : []
                }
            )
        }
    }, [user])

    const handleFormItemChange = (name, value) => {
        setNewSkill(
            {
                ...newSkill,
                [name]: value
            }
        )
    }

    const saveSkills = () => {
        let allSkills = []
        if (newSkill._id) {
            allSkills = skillsPayload.skills.map(skill => {
                if (skill._id === newSkill._id) {
                    skill.name = newSkill.name;
                    skill.level = newSkill.level
                }
                return skill
            })
        } else {
            allSkills = [...skillsPayload.skills, { name: newSkill.name, level: newSkill.level }];
        }
        const reqPayload = {
            skills: allSkills,
            desiredSkills: [...skillsPayload.desiredSkills],
        }
        updateProfile(reqPayload);
    }

    const deleteSkill = (data) => {
        let allSkills = skillsPayload.skills.filter(skill => skill._id !== data._id);
        const reqPayload = {
            skills: allSkills,
            desiredSkills: [...skillsPayload.desiredSkills],
        }
        updateProfile(reqPayload);
    }

    const saveDesiredSkills = () => {
        const reqPayload = {
            ...skillsPayload,
            desiredSkills: [...skillsPayload.desiredSkills, newDesiredSkill],
        }
        updateProfile(reqPayload);
    }

    const deleteDesiredSkill = (item) => {
        let allDesiredSkills = skillsPayload.desiredSkills.filter(skill => skill !== item);
        const reqPayload = {
            ...skillsPayload,
            desiredSkills: allDesiredSkills,
        }
        updateProfile(reqPayload);
    }

    const updateProfile = async (reqPayload) => {
        const resp = await fetchData({ url: URLS.updateProfile, method: 'post', body: reqPayload })
        if (resp.status === 200) {
            reloadProfile();
            setNewSkill(newSkillItem);
            setShowSkillsForm(false);
        }
    }

    return (
        <div>
            <div className="card mb-3 skills">
                <div className="p-3 bg-white rounded header d-flex justify-content-between align-items-center">
                    <p className="m-0 h5 d-inline-flex">Current Skills</p>
                    {showSkillsForm ?
                        <Button variant="contained" size="small" onClick={saveSkills} disabled={newSkill.level === "" || newSkill.name === ""}> Save</Button> :
                        <Button variant="contained" size="small" onClick={() => { setShowSkillsForm(true) }}> Add</Button>}
                </div>
                {showSkillsForm ? <Box p={3} pt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Skill Name" value={newSkill.name} variant="outlined"
                                onChange={(e) => handleFormItemChange('name', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="level">Skill Level</InputLabel>
                                <Select
                                    labelId="level"
                                    id="level"
                                    value={newSkill.level}
                                    onChange={(e) => handleFormItemChange('level', e.target.value)}
                                    label={'Skill Level'}
                                >
                                    {skillLevels.map(opt =>
                                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box> : null}
                <div className="table-responsive ms-4 px-2">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Skills</th>
                                <th scope="col">Level</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                skillsPayload && skillsPayload.skills.map((data, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{data.level}</td>
                                        <td className="p-1">
                                            <IconButton aria-label="Edit" size="small">
                                                <Tooltip title="Edit" placement="top" arrow>
                                                    <EditIcon className="text-primary" onClick={() => {
                                                        setNewSkill({
                                                            name: data.name,
                                                            level: data.level,
                                                            _id: data._id
                                                        });
                                                        setShowSkillsForm(true);
                                                    }} /></Tooltip>
                                            </IconButton>
                                            <IconButton aria-label="Delete" size="small">
                                                <Tooltip title="Delete" placement="top" arrow>
                                                    <DeleteIcon className="text-danger" onClick={() => deleteSkill(data)} />
                                                </Tooltip>
                                            </IconButton>
                                        </td>
                                    </tr>)
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </div>
            <div className="card mb-3 skills">
                <div className="p-3 bg-white rounded header d-flex justify-content-between align-items-center">
                    <p className="m-0 h5 d-inline-flex">Desired Skills</p>
                    {showDesiredSkillsForm ?
                        <Button variant="contained" size="small" onClick={() => {
                            saveDesiredSkills()
                            setShowDesiredSkillsForm(false)
                            setNewDesiredSkill('')
                        }}> Save</Button> :
                        <Button variant="contained" size="small" onClick={() => { setShowDesiredSkillsForm(true) }}>Add</Button>}
                </div>
                {
                    showDesiredSkillsForm ? <Box p={3} pt={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Skill Name" value={newDesiredSkill} variant="outlined"
                                    onChange={(e) => { setNewDesiredSkill(e.target.value) }} />
                            </Grid>
                        </Grid>
                    </Box> : null
                }
                <div className="ps-3 d-flex">
                    {
                        skillsPayload.desiredSkills.map(skill =>
                            <p className="border rounded text-bg-light p-2 me-2">{skill}
                                <Tooltip title="Delete" placement="top" arrow><ClearIcon onClick={() => { deleteDesiredSkill(skill) }} className="p-1 ms-1 btn border-0" /></Tooltip></p>
                        )
                    }
                </div>
            </div>
        </div >
    )
}

export default UserProfileSkills