import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { encrypt } from "../../helpers/utilities";
import { Container } from "@mui/system";
import { InterViewStatus } from "./InterViewStatus";
import { downloadLink } from "../../helpers/constFIles";
import { Box, Grid, TextField, MenuItem } from "@mui/material";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Tooltip from "@mui/material/Tooltip";
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import ResumeViewModal from "./ResumeViewModal";
import MUILink from "@mui/material/Link";
import ResumeButton from "@mui/material/Button";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";

const Recruiter = () => {
 
  const [errMsg, setErrMsg] = useState([{ pan: "", aadhar: "" }]);
 
  const [pageNumber, setPageNumber] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {};
    if (data.candidateID) {
      for (const [key, value] of Object.entries(data)) {
        payload[key] = value;
      }
    } else {
      for (const [key, value] of Object.entries(data)) {
        if (key !== "_id") {
          payload[key] = value;
        }
      }
    }
    const { status, message } = await fetchData({
      url: URLS.addCandidate,
      method: "post",
      body: payload,
    });
    if (status === 200) {
      toast.success(message);
      GetCandidates();
      modelHandle();
    } else {
      toast.error(message, "error");
    }
  };

  const { fetchData } = useAxios();
  
  const [resumeView, setResumeView] = useState({
    filePath: "",
    filetype: "",
    resumeName: "",
  });

  const [isStatusModel, setIsStatusModel] = useState(false);

  const [candidates, setCandidates] = useState([]);

  const [loading, setLoading] = useState(true);

  const [resumeShow, setResumeShow] = useState(false);

  const modelHandle = () => {
    if (isStatusModel) {
      resetModel();
    }
    setIsStatusModel(!isStatusModel);
  };

  const resetModel = () => {
    setData({
      candidateID: "",
      jobTitle: "",
      experienceFrom: "",
      experienceTo: "",
      skills: "",
      requirements: "",
      jobID: jds.length ? jds[0]._id : "",
      name: "",
      email: "",
      phone: "",
      resumeUrl: "",
      experience: "",
      PAN: "",
      AADHAR: "",
      currentCTC: "",
      expectedCTC: "",
      noticePeroid: "",
      willJoinEarly: "",
      employeeID: "",
    });
  };

  const [jds, setJds] = useState([]);

  const [data, setData] = useState({
    candidateID: "",
    skills: "",
    jobID: "",
    name: "",
    email: "",
    phone: "",
    resumeUrl: "",
    experience: "",
    PAN: "",
    AADHAR: "",
    currentCTC: "",
    expectedCTC: "",
    noticePeroid: "",
    willJoinEarly: "",
    status: "",
    comments: [],
  });

  const handlePAN = (e) => {
    var regex = /[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}$/;
    if (!regex.test(e.target.value)) {
      setErrMsg(...errMsg, [{ name: "error" }]);
    } else {
      setErrMsg({});
    }
  };

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    GetJobJds();
    GetCandidates();
  }, []);

  const GetJobJds = async () => {
    const resp = await fetchData({ url: URLS.getJobs, method: "get" });
    const mapData =
      resp.data &&
      resp.data.map((x) => {
        return { ...x, label: x.jobTitle };
      });
    if (mapData.length) {
      setData({
        ...data,
        jobID: mapData[0].jobID,
      });
    }
    setJds(mapData);
  };

  const GetCandidates = async () => {
    const resp = await fetchData({ url: URLS.getMyCandidates, method: "get" });
    setCandidates(resp.data);
    setLoading(false);
  };

  const onFileUpload = async (event) => {
    const formData = new FormData();

    formData.append(
      "resume",
      event.target.files[0],
      event.target.files[0].name
    );

    const resp = await fetchData({
      url: URLS.resumeUpload,
      method: "post",
      body: formData,
    });

    if (resp.status === 200) {
      if (
        resp.data &&
        resp.data.resumedata &&
        resp.data.resumedata.email &&
        resp.data.resumedata.name
      ) {
        setData({
          ...data,
          ["resumeUrl"]: resp.data.filename,
          ["email"]: resp.data.resumedata.email,
          ["name"]: resp.data.resumedata.name,
        });
      } else {
        setData({
          ...data,
          ["resumeUrl"]: resp.data.filename,
        });
      }
      toast.success(resp.message);
    } else {
      toast.error(resp.message, "error");
    }
  };

  const handleChangeProfiler = (value) => {
    setData({
      ...data,
      jobID: value.jobID,
    });
  };

  const candidateResume = (data) => {
    setResumeView({
      ...resumeView,
      filePath: downloadLink + data.resumeUrl,
      filetype: data.resumeUrl.split(".").at(-1),
      resumeName: data.name,
    });
    setResumeShow(true);
  };

  const resumeHide = () => {
    setResumeShow(false);
  };

  const getPageNo = (data) => {
    setPageNumber(data)
  }

  const countPerPage = 10;

  return (
    <div className="">
      <div className="d-flex">
        <h2 className="title">Candidates</h2>
        <div className="ms-auto">
          <button
            type="button"
            onClick={() => {
              modelHandle();
            }}
            className="btn btn-primary"
          >
            Add Candidate
          </button>
        </div>
      </div>
      <div className="bg-wht">
        <div className="col-md-12"></div>
        {!loading ? (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Experience</th>
                  <th scope="col">AADHAR or PAN</th>
                  <th scope="col">Expected CTC</th>
                  <th scope="col">Current CTC</th>
                  <th scope="col">Skills</th>
                  <th scope="col">Resume</th>
                  <th scope="col">Round</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {candidates &&
                    candidates.slice((pageNumber-1)*countPerPage,pageNumber*countPerPage).map((cand)=>{
                    return (
                      <tr>
                        <td>{cand.candidateID}</td>
                        <td>{cand.name}</td>
                        <td>{cand.email}</td>
                        <td>{cand.phone}</td>
                        <td>{cand.experience}</td>
                        <td>{cand.AADHAR || cand.PAN}</td>
                        <td>{cand.expectedCTC}</td>
                        <td>{cand.currentCTC}</td>
                        <td>
                          <p className="skills-style">{cand.skills}</p>
                        </td>
                        <td
                          onClick={(e) => {
                            candidateResume(cand);
                          }}
                        >
                          <MUILink component="button"><ResumeButton variant="text">Resume</ResumeButton></MUILink>
                        </td >
                        <td>
                          {
                            cand.interviews_list.length > 0
                              ? cand.interviews_list[
                                cand.interviews_list.length - 1
                              ].round
                              : "-"
                          }
                        </td >
                        <td style={{ whiteSpace: "nowrap" }}>
                          <InterViewStatus
                            status={
                              cand.interviews_list.length
                                ? cand.interviews_list[
                                  cand.interviews_list.length - 1
                                ].status
                                : ""
                            }
                          />
                        </td>
                        <td>
                          <div className="d-flex">
                            <Tooltip title="Edit Details" placement="top">
                              <button className="btn btn-primary p-0 me-1">
                                <EditIcon
                                  className="p-1 text-white"
                                  onClick={(e) => {
                                    setData(cand);
                                    modelHandle();
                                  }}
                                />
                              </button>
                            </Tooltip>
                            <Tooltip title="View Details" placement="top">
                              <button className="btn btn-secondary p-0 me-1">
                                <Link
                                  to={`candidatedetails/${encrypt(
                                    cand.candidateID
                                  )}`}
                                >
                                  <VisibilityIcon className="p-1 text-white" />
                                </Link>
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr >
                    );
                  })}
              </tbody >
            </table >
            {candidates && candidates.length > 0 ? <PaginationGlobal count={Math.ceil(candidates.length / countPerPage)} 
            pageNumber={pageNumber} func={getPageNo} /> : "" }
            {!loading && candidates.length === 0 ? <NoData /> : null}
          </div >
        ) : (
          <SkeletonLoader />
        )}
        <Modal
          size="xl"
          show={isStatusModel}
          onHide={modelHandle}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {data.candidateID ? "Update" : "Add"} Candidate
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box component={"form"} onSubmit={handleSubmit} pt={3}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Box pl={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Profiler"
                          required
                          size="small"
                          select
                          value={data.jobID}
                        >
                          {jds.map((option) => (
                            <MenuItem
                              key={"select"}
                              value={option.jobID}
                              onClick={() => handleChangeProfiler(option)}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Name"
                          value={data.name}
                          onChange={handleOnChange}
                          name={"name"}
                          required
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type={"email"}
                          onChange={handleOnChange}
                          value={data.email}
                          fullWidth
                          label="Email"
                          name={"email"}
                          required
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          onChange={handleOnChange}
                          value={data.phone}
                          fullWidth
                          label="Phone"
                          name={"phone"}
                          size="small"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          onChange={handleOnChange}
                          value={data.experience}
                          label="Experience"
                          size="small"
                          name="experience"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="PAN"
                          size="small"
                          value={data.PAN}
                          onChange={handleOnChange}
                          name="PAN"
                          onBlur={handlePAN}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="AADHAR"
                          name={"AADHAR"}
                          value={data.AADHAR}
                          onChange={handleOnChange}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Current CTC"
                          onChange={handleOnChange}
                          value={data.currentCTC}
                          name={"currentCTC"}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Expected CTC"
                          onChange={handleOnChange}
                          value={data.expectedCTC}
                          name={"expectedCTC"}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Notice Period(Days)"
                          value={data.noticePeroid}
                          onChange={handleOnChange}
                          name={"noticePeroid"}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Will join early(Days)"
                          value={data.willJoinEarly}
                          onChange={handleOnChange}
                          name={"willJoinEarly"}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth
                          multiline
                          rows={4}
                          label="Skills"
                          value={data.skills}
                          onChange={handleOnChange}
                          name={"skills"}
                          size="small"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Container>
                    <div class="image-upload">
                      <label style={{ cursor: "pointer" }} for="file_upload">
                        <img src="" alt="" class="uploaded-image" />
                        <div class="h-100">
                          <div class="dplay-tbl">
                            <div class="dplay-tbl-cell">
                              <i class="fa fa-cloud-upload"></i>
                              <h5>
                                <b>Choose Your CV to Upload</b>
                              </h5>
                              <h6 class="mt-3">Or Drop Your CV Here</h6>
                            </div>
                          </div>
                        </div>
                        <input onChange={onFileUpload} accept=".pdf,.docx" data-required="image" type="file" name="image_name" id="file_upload" className="image-input" data-traget-resolution="image_resolution" />
                      </label>
                    </div>
                    {data.resumeUrl && (
                      <div className="d-flex align-items-center">
                        <a href={downloadLink + data.resumeUrl} target="_blank" rel="noopener noreferrer">
                          {data.resumeUrl}
                        </a>
                        <Tooltip title="Delete Resume" placement="top">
                          <CloseOutlinedIcon className="text-danger ms-2" style={{ cursor: "pointer" }}
                            onClick={() =>
                              setData({
                                ...data,
                                resumeUrl: "",
                              })
                            }
                          />
                        </Tooltip>
                      </div>
                    )}
                  </Container>
                </Grid>
              </Grid>
              <Box display={"flex"} justifyContent={"end"} pt={4} pb={2} pr={3}>
                <Box mr={2}>
                  <Button variant="secondary" onClick={modelHandle}>
                    Close
                  </Button>
                </Box>
                <Box>
                  <Button type="submit" variant="primary">
                    {data.candidateID ? "Update" : "Add"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal.Body>
        </Modal>
      </div >
      <ResumeViewModal resumeData={resumeView} show={resumeShow} onHide={resumeHide} />
    </div >
  );
};

export default Recruiter;
