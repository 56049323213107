import { Card, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function PaginationGlobal(props) {
    return (
        <Card>
            <Pagination sx={{ mb: 1, mt: 1, display:"flex", justifyContent:'center' }} size='medium' variant="outlined" shape="rounded" count={props.count} showFirstButton
                page={props.pageNumber} color='primary'
                showLastButton onChange={(e, value) => props.func(value)}
                renderItem={(item) =>
                    <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}  {...item}></PaginationItem>}
            >
            </Pagination>
        </Card>
    )
}