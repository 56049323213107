import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { encrypt } from "../../helpers/utilities";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";

const EmployeeTable = ({ employeesList }) => {

    const navigate = useNavigate();

    const [pageNumber, setPageNumber] = useState(1);

    const getPageNo = (data) => {       
        setPageNumber(data)
    }
    useEffect(()=>
    {       
        setPageNumber(1)
    },[employeesList])
    const countPerPage = 10;

    return (
        <div className='content table-responsive tab-styles'>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" >empid</th>
                        <th scope="col" >employee name</th>
                        <th scope="col" >designation</th>
                        <th scope="col" >email</th>
                        <th scope="col" >mobile</th>
                        <th scope="col" >manager name</th>
                        <th scope="col" >action</th>
                    </tr>
                </thead>
                <tbody>
                    {employeesList.slice((pageNumber - 1) * countPerPage,
                        pageNumber * countPerPage).map((emp) => {
                            return <tr key={emp.employeeCode}>
                                <td>{emp.employeeCode}</td>
                                <td >{emp.employeeName}</td>
                                <td >{emp.designation}</td>
                                <td >{emp.emailID}</td>
                                <td >{emp.mobile}</td>
                                <td >{emp.managerName}</td>
                                <td>
                                    <Tooltip title="View Reports" placement="top" arrow>
                                        <button className="btn btn-secondary p-0 m-0">
                                            <VisibilityIcon onClick={(e => {
                                                navigate(`/report-view/${encrypt("employeeCode")}/${encrypt(emp.employeeCode)}`);
                                            })}
                                                className="p-1" />
                                        </button>
                                    </Tooltip>
                                </td>
                            </tr>
                        })}
                </tbody>
            </table>
            {
                employeesList && employeesList.length > 0 ? <PaginationGlobal count={Math.ceil(employeesList.length / countPerPage)}
                    pageNumber={pageNumber} func={getPageNo} /> : ""
            }
        </div>
    )
}

export default EmployeeTable