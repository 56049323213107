import React, { useEffect, useState } from 'react'
import { Box, Grid, TextField, Button, InputLabel, Select, FormControl, MenuItem, Autocomplete } from '@mui/material';
import { URLS } from "../../helpers/URLS";
import { toast } from 'react-toastify';
import useAxios from "../../helpers/Hooks/useAxios";
import DateWithTimePicker from '../Common/DateWithTimePicker';
import moment from 'moment';

const roundOptions = [
    { value: 'Technical', label: 'Technical' },
    { value: 'Hr', label: 'Hr' },
    { value: 'Written', label: 'Written' }
]

const interviewoptions = [
    { value: 'Face-to-Face', label: 'Face to Face' },
    { value: 'Virtual', label: 'Virtual' }
]

const AddInterview = (props) => {
    
    const { fetchData } = useAxios();
    
    const { setEditInterview, selectedRoundData, reloadCandidate } = props;
    
    const [employeeList, setEmployeeList] = useState([]);
    
    const [interviewPayload, setInterviewPayload] = useState({
        interViewID: "",
        candidateID: "",
        round: 1,
        roundType: "",
        startTime: null,
        endTime: null,
        scheduledTo: "",
        interViewType: "",
        status: "",
        review: [],
        comments: ""
    })

    useEffect(() => {
        setDataForTheInterviewPaytload(selectedRoundData);
    }, [selectedRoundData])

    const setDataForTheInterviewPaytload = (interview) => {
        setInterviewPayload(payload => {
            return {
                ...payload,
                round: interview.round,
                interViewID: interview.interViewID,
                candidateID: interview.candidateID,
                roundType: interview.roundType || '',
                startTime: new Date(interview?.startTime),
                endTime: new Date(interview?.endTime),
                scheduledTo: interview?.scheduledTo?.employeeCode || "",
                interViewType: interview?.interViewType || ''
            }
        })
    }

    useEffect(() => {
        getEmployeeList();
    }, [])

    const getEmployeeList = async () => {
        const resp = await fetchData({ url: `${URLS.getEmpoyeesLight}`, method: 'get' });
        if (resp.status === 200) {
            const list = resp.data.map(x => {
                return {
                    label: `${x.employeeName} - ${x.employeeCode}`,
                    employeeCode: x.employeeCode
                }
            })
            setEmployeeList(list)
        }
    }

    const handleOnChange = (e) => {
        setInterviewPayload({
            ...interviewPayload,
            [e.target.name]: e.target.value
        })
    }

    const getScheduledToLable = (emplist, scheduledTo) => {
        const employee = emplist.filter(x => x.employeeCode === scheduledTo);
        if (employee && employee.length > 0) {
            return employee[0].label
        }
        return ''
    }

    const checkFormIsValidate = () => {
        if (
            interviewPayload.candidateID &&
            interviewPayload.round &&
            interviewPayload.roundType &&
            interviewPayload.startTime &&
            interviewPayload.endTime &&
            interviewPayload.scheduledTo &&
            interviewPayload.interViewType
        ) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!checkFormIsValidate()) {
            toast.error('Invalid Form!');
            return;
        }
        debugger
        const { status, message } = await fetchData({ url: `${URLS.schedule}`, method: 'post', body: { ...interviewPayload } });
        if (status === 200) {
            toast.success(message);
            reloadCandidate()
            setEditInterview(false);
        } else {
            toast.error(message);
        }

    }

    return (
        <Box>
            <Box component={'form'} onSubmit={handleSubmit} >
                <Box component={'h4'} mb={3}> Interview Round - {interviewPayload.round} </Box>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <TextField fullWidth value={interviewPayload.round} label="Round" required size="small" disabled name={'round'} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="round-type">Round Type</InputLabel>
                            <Select labelId="round-type" id="round-type" value={interviewPayload.roundType}
                                label="Round Type" name="roundType" onChange={handleOnChange} >
                                {roundOptions && roundOptions.map((opt, index) =>
                                    <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <Autocomplete disablePortal id="scheduled-to" size="small" fullWidth options={employeeList}
                            name={'scheduledTo'} onChange={(e, value) => {
                                setInterviewPayload({
                                    ...interviewPayload,
                                    'scheduledTo': value && value.employeeCode ? value.employeeCode : ''
                                })
                            }}
                            value={getScheduledToLable(employeeList, interviewPayload.scheduledTo)}
                            renderInput={(params) =>
                                <TextField {...params} label="Scheduled To " />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="interview-type">Interview Type</InputLabel>
                            <Select labelId="interview-type" id="interview-type"
                                value={interviewPayload.interViewType}
                                label="Interview Type"
                                name="interViewType"
                                onChange={handleOnChange}
                            >
                                {interviewoptions && interviewoptions.map((opt, index) =>
                                    <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <DateWithTimePicker label={'Start Time'} defaultDate={interviewPayload.startTime}
                            getDate={(date) => {
                                setInterviewPayload({
                                    ...interviewPayload,
                                    'startTime': date
                                })
                            }}
                            minDate={new Date()} 
                            minTime={moment(new Date()).format('L') == moment(interviewPayload.startTime).format('L') ? new Date() : null}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateWithTimePicker label={'End Time'} defaultDate={interviewPayload.endTime}
                            getDate={(date) => {
                                setInterviewPayload({
                                    ...interviewPayload,
                                    'endTime': date
                                })
                            }}
                            minDate={new Date(interviewPayload.startTime)} 
                            minTime={moment(interviewPayload.startTime).format('L') == moment(interviewPayload.endTime).format('L') ? new Date(interviewPayload.startTime) : null}
                        />
                    </Grid>
                </Grid>
                <Box pt={3} textAlign={'end'}>
                    <Button type='reset' variant="contained" color={'inherit'} onClick={() => { setEditInterview(false) }} className={'me-2'}>Cancel</Button>
                    <Button type='submit' variant="contained">Save</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default AddInterview
