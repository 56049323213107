import React from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";

const Piechart = (props) => {
  return (
    <div >
      <Pie
        className="mt-2"
        style={{ margin: "auto", width: '50%', height: '70px' }}
        data={props.chartData}
        options={{
          legend: {
            display: false,
          }
        }}
      />
    </div>
  );
}

export default Piechart;
