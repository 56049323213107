import useAxios from "../../helpers/Hooks/useAxios";
import { useEffect, useState } from "react";
import { URLS } from "../../helpers/URLS";
import NoData from "../../loaders/NoData";
import ProjectsTable from "../Common/ProjectsTable";

export default function ProjectReport() {
    const { fetchData } = useAxios();
    const [isLoad, setIsLoad] = useState(false);
    const [projectReports,setProjectReports]=useState([]);


    
    const getProjectReport=async()=>
    {
        const resp = await fetchData({ url: URLS.getProjectTeamList, method: 'get' });
        if(resp.status==200)
        {
          
            setProjectReports(resp.data);
        }
        
    }
    useEffect(()=>
        {getProjectReport()}
    ,[])
    return (
        <div className=''>
            <div className="">
                <h4 className="title mb-3">Team Project Report</h4>
               
        {/* {
            projectReport.length>0 ? <ProjectsTable projectList={projectReport} />:<NoData/>
        } */}
       {
         <ProjectsTable projectList={projectReports} /> 
       }

            </div>
        </div>

    )
}