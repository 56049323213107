import React, { useState, useEffect } from 'react'
import { downloadLink } from "../../helpers/constFIles";
import { InterViewStatus } from './InterViewStatus';
import { IconButton } from '@mui/material';
import { URLS } from '../../helpers/URLS';
import useAxios from "../../helpers/Hooks/useAxios";
import Modal from "react-bootstrap/Modal";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CircleIcon from '@mui/icons-material/Circle';
import AddInterview from './AddInterview';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import Button from '@mui/material/Button';

const ViewCandidateDetailsModal = ({ candidateID, show, onHide }) => {

  const [candidate, setCandidate] = useState({})

  const [statusList, setStatusList] = useState([]);

  const [selectedRound, setSelectedRound] = useState(0);

  const [currentRound, setCurrentRound] = useState(0);

  const [selectedRoundData, setSelectedRoundData] = useState({});

  const [editInterview, setEditInterview] = useState(false);

  const { fetchData } = useAxios();

  useEffect(() => {
    if (candidateID) {
      getParticularCandidate(candidateID);
    }
  }, [candidateID])

  useEffect(() => {
    let numberOfRounds = [];
    for (let i = 0; i < candidate?.job?.numberOfRounds; i++) {
      numberOfRounds.push(i);
    }
    setStatusList([...numberOfRounds]);
    if (candidate && candidate.interviews && candidate.interviews.length > 0) {
      if (candidate.job.numberOfRounds === candidate.interviews.length) {
        if (candidate.interviews.at(-1).status === 'Selected' || candidate.interviews.at(-1).status === 'Rejected') {
          setSelectedRound(0);
          setCurrentRound(0);
        } else {
          setSelectedRound(candidate.interviews.length - 1);
          setCurrentRound(candidate.interviews.length - 1);
        }
      } else {
        candidate.interviews.forEach((interview, i) => {
          if (interview.status === 'Selected') {
            setSelectedRound(i + 1);
            setCurrentRound(i + 1)
          } else if (interview.status === 'Rejected') {
            setSelectedRound(i);
            setCurrentRound(i)
          } else {
            setSelectedRound(i);
            setCurrentRound(i)
          }
        })
      }
    }
  }, [candidate])

  useEffect(() => {
    if (candidate && candidate.interviews && candidate.interviews.length > 0 && candidate.interviews[selectedRound]) {
      setSelectedRoundData({ ...candidate.interviews[selectedRound], round: +candidate.interviews[selectedRound].round })
    } else {
      setSelectedRoundData({
        round: selectedRound ? selectedRound + 1 : 1
      })
    }
  }, [selectedRound, candidate])

  const getParticularCandidate = async (candidateID) => {
    const { status, data } = await fetchData({ url: URLS.getParticularCandidate(candidateID), method: 'get' });
    if (status === 200) {
      setCandidate(data);
    } else {
      setCandidate({})
    }
  }

  return (
    <div>
      <Modal show={show} onHide={() => { setEditInterview(false); onHide(); }} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Candidate Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mb-3">
            <div className="card-body">
              <div className='row'>
                <div className='col-md-8'>
                  <div className="d-sm-flex d-flex">
                    <div className="d-flex">
                      <div
                        className="bg-secondary me-3"
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          className="w-100 rounded-circle"
                          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        />
                      </div>
                      <div>
                        <div className="d-block d-md-none d-sm-none">
                          <a href={downloadLink + candidate.resumeUrl} target="_blank"
                            className="d-flex align-items-center"
                          >
                            <VisibilityOutlinedIcon className="text-white" />
                            <p className="m-0 ps-2">View Resume</p>
                          </a>
                        </div>
                        <div className="d-sm-flex mt-2">
                          <div className="align-items-center me-3">
                            <BadgeOutlinedIcon className="text-secondary" />
                            <span className="m-0 ps-1">
                              {candidate.candidateID}
                            </span>
                          </div>
                          <div className=" align-items-center me-3">
                            <PermIdentityIcon className="text-secondary" />
                            <span className="m-0 ps-1">
                              {candidate.name}
                            </span>
                          </div>
                          <div className=" align-items-center me-3">
                            <AlternateEmailIcon className="text-secondary" />
                            <span className="m-0 ps-1">
                              {candidate.email}
                            </span>
                          </div>
                          <div className=" align-items-center me-3">
                            <PhoneInTalkOutlinedIcon className="text-secondary" />
                            <span className="m-0 ps-1">
                              {candidate.phone}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-lg-4'>
                  <div className="d-flex justify-md-content-end justify-content-sm-end mx-4">
                    <div className='content-wrap'>
                      {statusList.map(i =>
                        <IconButton key={i} size="small" onClick={() => { setSelectedRound(i) }}
                          color={
                            (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === '') ? 'primary' :
                              (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === 'Selected') ? 'success' :
                                (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === 'Rejected') ? 'error' :
                                  (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === 'Hold') ? 'warning' : 'inherit'
                          }
                          disabled={
                            (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === '') ? false :
                              (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === 'Selected') ? false :
                                (candidate && candidate?.interviews && candidate?.interviews[i] && candidate?.interviews[i].status === 'Rejected') ? false :
                                  (currentRound >= i) ? false : true
                          }
                          className={`ms-4 statusLine ${(selectedRound === i) ? ' border border-primary' : ''}`}
                        >
                          <CircleIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6 col-sm-12 col-lg-3">
                  <div className="d-flex mb-2">
                    <p className="fw-bold me-2">Current CTC:</p>
                    <p>  {candidate.currentCTC} LPA</p>
                  </div>
                  <div className="d-flex mb-2">
                    <p className="fw-bold me-2">Skills:</p>
                    <p>  {candidate.skills}</p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-3">
                  <div className="d-flex mb-2">
                    <p className="fw-bold me-2">Experience:</p>
                    <p>
                      {candidate.experience} Years
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="fw-bold me-2">Expected CTC:</p>
                    <p>  {candidate.expectedCTC} LPA</p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-3">
                  <div className="d-flex mb-2">
                    <p className="fw-bold me-2">PAN/AADHAR:</p>
                    <p>
                      {
                        candidate.AADHAR || candidate.PAN
                      }
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="fw-bold me-2">Will Join Early:</p>
                    <p>  {candidate.willJoinEarly} Days</p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-3">
                  <div className="d-flex mb-2">
                    <p className="fw-bold me-2">Notice Peroid:</p>
                    <p className="mb-2">
                      {candidate.noticePeroid} Days
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <p className="fw-bold me-2 ">Position:</p>
                    <p >  {candidate?.job?.jobTitle}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              {
                editInterview ?
                  <AddInterview setEditInterview={setEditInterview} selectedRoundData={{ ...selectedRoundData, candidateID: candidate.candidateID }}
                    reloadCandidate={() => { getParticularCandidate(candidate.candidateID) }}
                  /> :
                  <div className='row'>
                    {(!selectedRoundData?.status || selectedRoundData?.status === '') ? (
                      <div className="col-md-12 text-end position-absolute">
                        {/* <IconButton size="small" onClick={() => { setEditInterview(true) }} color={'primary'} >
                          <EditIcon />
                        </IconButton> */}
                        <Button variant="contained" size="small"  onClick={() => { setEditInterview(true) }} >Schedule Round : {selectedRoundData?.round || selectedRound + 1}</Button>
                      </div>
                    ) : ''}
                    <div className='col-6'>
                      <p> <b> Round:</b> {selectedRoundData?.round || selectedRound + 1}</p>
                      <p><b>Scheduled By:</b> {selectedRoundData?.scheduledBy?.employeeName ? `${selectedRoundData?.scheduledBy?.employeeName} - (${selectedRoundData?.scheduledBy?.employeeCode})` : ''}</p>
                      <p><b>Interviewer:</b> {selectedRoundData?.scheduledTo?.employeeName ? `${selectedRoundData?.scheduledTo?.employeeName} - (${selectedRoundData?.scheduledTo?.employeeCode})` : ''}</p>
                      <p><b>Start Time:</b> {selectedRoundData?.startTime ? moment(selectedRoundData?.startTime).format('lll') : ''}</p>
                      <p><b>End Time:</b> {selectedRoundData?.endTime ? moment(selectedRoundData?.endTime).format('lll') : ''}</p>
                    </div>
                    <div className='col-6'>
                      <p><b>Round Type:</b> {selectedRoundData?.roundType}</p>
                      <p><b>Status:</b> {selectedRoundData?.roundType ? <InterViewStatus status={selectedRoundData?.status} /> : ''} </p>
                      <p><b>Performance Score:</b> </p>
                      {selectedRoundData && selectedRoundData?.review && selectedRoundData?.review.map(item =>
                        <div class="border p-1 my-2 rounded">
                          <div className="p-1 px-4 row d-flex align-items-center rounded row">
                            <span className="d-block text-success col-5"> {item?.label}</span>
                            <span className="text-success col-3">Rating: {item?.rating}</span>
                            <span className="text-success col-4"> {item?.value}</span>
                          </div>
                        </div>
                      )
                      }
                      <p><b>Comments:</b> {selectedRoundData?.comments}</p>
                    </div>
                  </div>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ViewCandidateDetailsModal;
