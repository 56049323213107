import React from 'react'
import Modal from "react-bootstrap/Modal";
import FileViewer from "react-file-viewer";

const ResumeViewModal = ({ resumeData, show, onHide }) => {

    const onError = e => {
        console.log(e, "error in file-viewer");
    };

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {resumeData.resumeName}-Resume
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='resume_view_modal'>
                    <FileViewer fileType={resumeData.filetype} filePath={resumeData.filePath} onError={onError} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResumeViewModal;
