import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const SkeletonLoader = () => {
  return (
    <div className="row">
      <div className="col-12">
        <Box sx={{ p: 2 }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      </div>
    </div>
  );
};
export default SkeletonLoader;
