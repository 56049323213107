import moment from "moment";
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';

const key = "motivitylab";

export const dateFormat = (date) => {
    try {
        return moment(date).format("DD/MM/YYYY");
    }
    catch (err) {
        console.log("dateFormat error:", err.message);
    }
}

export const encrypt = (string) => {
    try {
        let stringData = AES.encrypt(string, key).toString();
        const s = stringData.split("/").length;
        for(let i=0;i<s;i++)
        {
            stringData = stringData.replace("/","&&&");
        }
        return stringData;
    } catch (err) {
        return "";
    }
}

export const decrypt = (ciphertext) => {
    try {
        const s = ciphertext.toString().split("&&&");
        s.forEach(x => {
            ciphertext = ciphertext.replace("&&&","/") 
        })
        return AES.decrypt(ciphertext, key).toString(CryptoJS.enc.Utf8);
    } catch (err) {
        return "";
    }
}

export const YYMMDD = (date) =>{
    return moment(date).format("DD-MM-YYYY")
}

export const timeView = (date) =>{
    return moment(date).format("hh:mm A")
}
