import React from "react";
import Nodata from "../assets/images/No-Data.png";

const NoData = () => {
  return (
    <div>
      <img className="mx-auto mt-3 d-block " width={100} src={Nodata} />
      <div className="text-center">No data found</div>
    </div>
  );
};
export default NoData;
