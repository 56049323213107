import React, { useEffect, useState } from "react";
import { URLS } from "../../helpers/URLS";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UnreadNotifications from "./UnreadNotifications";
import AllNotifications from "./AllNotifications";
import useAxios from "../../helpers/Hooks/useAxios";
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import './Notifications.scss'

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const NotificationTab = ({ setOpenNotificationPanel, getValue, setNumber, defaultTab = 0 }) => {

    const { fetchData } = useAxios();

    const [value, setValue] = React.useState(defaultTab || 0);
    const [allNotifications, setAllNotifications] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getAllNotifications();
    }, []);


    const getAllNotifications = async () => {
        const response = await fetchData({ url: URLS.getMyNotifications, method: "get" });
        if (response.status === 200) {
            const allNotices = response.data
            allNotices.forEach((notify) => {
                notify.icon = null;
                if (notify.notification.title === 'Leave Request') {
                    notify.icon = <div className="rounded-circle bg-warning d-flex justify-content-center align-items-center p-2">
                        <DateRangeIcon className="text-white" /></div>
                } else if (notify.notification.title === 'Work Report') {
                    notify.icon = <div className="rounded-circle bg-primary d-flex justify-content-center align-items-center p-2">
                        <AssignmentIndOutlinedIcon className="text-white" /></div>
                }
                else if (notify.notification.title === 'Project') {
                    notify.icon = <div className="rounded-circle bg-success d-flex justify-content-center align-items-center p-2">
                        <AccountTreeIcon className="text-white" /></div>
                }
                else if (notify.notification.title === 'Interview')
                    notify.icon = <div className="rounded-circle bg-secondary d-flex justify-content-center align-items-center p-2">
                        <PersonSearchIcon className="text-white" /></div>
            })
            setAllNotifications(allNotices)
        }
    }

    return (
        <Box sx={{ width: '100%' }} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Unread" {...a11yProps(0)} />
                    <Tab label="All" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UnreadNotifications getValue={getValue} setNumber={setNumber} allNotifications={allNotifications} setOpenNotificationPanel={setOpenNotificationPanel} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AllNotifications allNotifications={allNotifications} />
            </TabPanel>
        </Box>
    )
}

export default NotificationTab