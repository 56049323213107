import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import moment from "moment";
import './Notifications.scss'

const UnreadNotifications = ({ allNotifications, setOpenNotificationPanel, getValue, setNumber }) => {

    const { fetchData } = useAxios();

    const navigate = useNavigate();

    const [unReadNotifications, setUnReadNotifications] = useState([]);

    useEffect(() => {
        getValue(unReadNotifications.length)
        const unReadNotifies = allNotifications.filter(each => !each.receiver.read)
        setUnReadNotifications(unReadNotifies);
    }, [allNotifications]);

    const readNotificationUpdate = async (notification) => {
        const payload = {
            _id: notification._id,
        }
        const resp = await fetchData({ url: `${URLS.updateNotificationReadStatus}`, method: 'post', body: payload });
        if (resp.status === 200) {
            setOpenNotificationPanel(false);
            navigate(resp.data.notification.data.onActionClick.bar.url);
        }
    }

    return (
        <div className="unread_notifications">
            {
                unReadNotifications.length > 0 ?
                    unReadNotifications.map((eachItem,index) => {
                        return (
                            <div key={index}>
                                <div className="notification_space_unread shadow-sm" onClick={() => { readNotificationUpdate(eachItem); }}>
                                    <div className="row">
                                        <div className="col-2">
                                            {eachItem.icon}
                                        </div>
                                        <div className="col-10 ps-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span><h6 className="mb-0">{eachItem.notification.title}</h6></span>
                                                <div className="d-flex align-items-center">
                                                    <p className="mb-0 notification_date">{moment(eachItem.createdDate).format('LT')}</p>
                                                    <div className="view_status ms-2"></div>
                                                </div>
                                            </div>
                                            
                                                <p className="mb-0 body_notifications">{eachItem.notification.body}</p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : <span><h4 className="d-flex align-items-center">No Unread Notifications</h4></span>
            }
        </div>
    )
}

export default UnreadNotifications