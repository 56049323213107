
let fileDownload = "http://localhost:5002/api/v1/download/"
const hostname = window.location.hostname;
// if (/^wrms.ohrange.in/.test(hostname)) {
//   fileDownload = 'https://api.wrms.ohrange.in/api/v1/download/'
// }
if (/^wrms.co.in/.test(hostname)) {
  fileDownload = 'https://wrms.co.in/api/v1/download/'
}

export const URLS = {
  login: "login",
  user: "user",
  auth: "auth",
  manageProject: "manageProject",
  getProjectList: "getProjectList",
  searchEmployees: "searchEmployees",
  projectTeamManage: "projectTeamManage",
  getTeamsList: "getTeamsList",
  manageTeam: "manageTeam",
  getWorkReportEmpolyees: "getWorkReportEmpolyees",
  getActivities: "getActivities",
  getAssignedProjects: "getAssignedProjects",
  manageDailyStatus: "manageDailyStatus",
  getDailyStatus: "getDailyStatus",
  logout: "logout",
  addCandidate: "addCandidate",
  addOrUpdateJob: "addOrUpdateJob",
  getJobs: "getJobs",
  resumeUpload: "resumeUpload",
  getMyCandidates: "getMyCandidates",
  getAllCandidates: "getAllCandidates",
  getParticularCandidate: (ID) => `getParticularCandidate/${ID}`,
  getEmpoyees: "getEmpoyees",
  getEmpoyeesLight: "getEmpoyeesLight",
  schedule: "schedule",
  getInterViewData: (CID, IID, EID) => `getInterViewData/${CID}/${IID}/${EID}`,
  assignInterViewList: "assignInterViewList",
  updateReview: "updateReview",
  getLeaveTypes: "getLeaveTypes",
  createLeaveRequest: "createLeaveRequest",
  getTeamLevelLeaveRequest: "getTeamLevelLeaveRequest",
  updateLeaveRequestStatus: "updateLeaveRequestStatus",
  getMyLeaveRequests: "getMyLeaveRequests",
  updateLeaveRequest: "updateLeaveRequest",
  getDashboardData: "getDashboardData",
  getScheduledCandidates: "getScheduledCandidates",
  getSelectedCandidates: "getSelectedCandidates",
  getRejectedCandidates: "getRejectedCandidates",
  updateProfile: 'updateProfile',
  getEmpWithSkills: 'getEmployeeCodeWithProjects',
  getTeamList: 'getTeamList',
  getProjectTeamList: 'getProjectTeamList',
  //notifications
  addNotificationEndPoints: 'addNotificationEndPoints',
  getMyNotifications: "getMyNotifications",
  updateNotificationReadStatus: "updateNotificationReadStatus",
  getDashboardLeavesData: "getDashboardLeavesData",
  getDashboardCalendarData: (startDate, endDate) => `getDashboardCalendarData?startDate=${startDate}&endDate=${endDate}`,
  activeAccountOtpGenerate: "activeAccountOtpGenerate",
  activeAccount: "activeAccount",
  getDailyStatusdownload: 'getDailyStatusdownload',
  fileDownloadServer: fileDownload

};


