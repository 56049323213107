export const holidayList = [
    {
        "id": 1,
        "holiday": "Pongal",
        "date": "16/01/2023",
        "dayOfWeek": "Monday"
    },
    {
        "id": 2,
        "holiday": "Republic day",
        "date": "26/01/2023",
        "dayOfWeek": "Thursday"
    },
    {
        "id": 3,
        "holiday": "Holi",
        "date": "08/03/2023",
        "dayOfWeek": "Wednesday"
    },
    {
        "id": 4,
        "holiday": "Ugadi",
        "date": "22/03/2023",
        "dayOfWeek": "Wednesday"
    },
    {
        "id": 5,
        "holiday": "Independence Day",
        "date": "15/08/2023",
        "dayOfWeek": "Tuesday"
    },
    {
        "id": 6,
        "holiday": "Vinayakachavithi",
        "date": "18/09/2023",
        "dayOfWeek": "Monday"
    },
    {
        "id": 7,
        "holiday": "Gandhi Jayanthi",
        "date": "02/10/2023",
        "dayOfWeek": "Monday"
    },
    {
        "id": 8,
        "holiday": "Dussehra",
        "date": "25/10/2023",
        "dayOfWeek": "Wednesday"
    },
    {
        "id": 9,
        "holiday": "Diwali",
        "date": "10/11/2023",
        "dayOfWeek": "Friday"
    },
    {
        "id": 10,
        "holiday": "Christmas",
        "date": "25/12/2023",
        "dayOfWeek": "Monday"
    }
]