import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

const ToolTip = (props) => {
    return (
        <Tooltip title={props.Text} color='primary' placement="top" arrow>
            <Box className={props.contentCalssNames}> {props.children}</Box>
        </Tooltip>
    )
}

export default ToolTip;