import { useContext, useState } from "react";
import LoaderContext from '../../Context/LoaderContext';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseUrl = '/api/v1/';

const useAxios = () => {
    const { setIsLoader } = useContext(LoaderContext);
    const [error, setError] = useState("");
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()

    const fetchData = ({ url, method, body = null }) => {
        setIsLoader(true)
        return new Promise((resolve) => {
            axios[method](baseUrl + url, body)
                .then((res) => {
                    if (res && res.data && res.data.status === 500) {
                        navigate('/login');
                    }
                    resolve(res.data);
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setIsLoader(false);
                });
        })
    }

    return { error, loading, fetchData };

}
export default useAxios;