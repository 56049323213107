import React from "react";
import { Bar } from "react-chartjs-2";

const Barchart = (props) => {
  return (
    <div>
      <Bar
        className="mt-2"
        data={props.chartData}
        options={{
          plugins: {
            legend: {
              display: true,
            },
          }
        }}
      />
    </div>
  );
}

export default Barchart;
