import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { downloadLink } from '../../helpers/constFIles';
import { Grid, MenuItem, TextField, Box, Container, Button, Typography } from '@mui/material';
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import ToolTip from '../../Tolltip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const AddCandidate = (props) => {

    const { show, onHide, reloadContent, selectedCandidateForEdit } = props;

    const [panErrMsg, setPanErrMsg] = useState(false);

    const [aadharErrMst, setAadharErrMsg] = useState(false);

    const { fetchData } = useAxios();

    const [jobs, setJobs] = useState([]);

    const [data, setData] = useState({
        candidateID: "",
        skills: '',
        jobID: '',
        name: '',
        email: '',
        phone: '',
        resumeUrl: '',
        experience: '',
        PAN: '',
        AADHAR: '',
        currentCTC: '',
        expectedCTC: '',
        noticePeroid: '',
        willJoinEarly: '',
        status: "",
        comments: []
    })

    useEffect(() => {
        GetJobJds()
    }, [])

    useEffect(() => {
        if (selectedCandidateForEdit && selectedCandidateForEdit._id) {
            setCandidateIfItHasForEdit(selectedCandidateForEdit)
        } else {
            resetModel();
        }
    }, [selectedCandidateForEdit])

    const GetJobJds = async () => {
        const resp = await fetchData({ url: URLS.getJobs, method: 'get' })
        const mapData = resp.data.map(x => { return { ...x, label: x.jobTitle } })
        if (mapData.length) {
            setData({
                ...data,
                jobID: mapData[0].jobID
            })
        }
        setJobs(mapData)
    }

    const setCandidateIfItHasForEdit = (candi) => {
        setData({
            candidateID: candi.candidateID,
            jobTitle: '',
            experienceFrom: '',
            experienceTo: '',
            skills: candi.skills,
            requirements: '',
            jobID: candi.jobID,
            name: candi.name,
            email: candi.email,
            phone: candi.phone,
            resumeUrl: candi.resumeUrl,
            experience: candi.experience,
            PAN: candi.PAN,
            AADHAR: candi.AADHAR,
            currentCTC: candi.currentCTC,
            expectedCTC: candi.expectedCTC,
            noticePeroid: candi.noticePeroid,
            willJoinEarly: candi.willJoinEarly,
            employeeID: '',
        })
    }

    const handlePAN = (e) => {
        var regex = /[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}$/;
        if (regex.test(e.target.value) || e.target.value.length === 0) {
            setPanErrMsg(false);
        }
        else {
            setPanErrMsg(true);
        }
    }

    const handleAADHAR = (e) => {
        if (e.target.value.length === 12 || e.target.value.length === 0) {
            setAadharErrMsg(false);
        }
        else {
            setAadharErrMsg(true);
        }
    }

    const handleError = (e) => {
        if (e.target.name === "phone") {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
            return true;
        }
        if (e.target.name === 'AADHAR') {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
            handleAADHAR(e)
        }

        if (e.target.name === "PAN") {
            e.target.value = e.target.value.toUpperCase();
            handlePAN(e);
            return true;
        }
    }

    const handleOnChange = (e) => {
        handleError(e);
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const onFileUpload = async (event) => {
        const formData = new FormData();
        formData.append(
            "resume",
            event.target.files[0],
            event.target.files[0].name
        );
        const resp = await fetchData({ url: URLS.resumeUpload, method: 'post', body: formData })
        if (resp.status === 200) {
            if (resp.data && resp.data.resumedata && resp.data.resumedata.email && resp.data.resumedata.name) {
                setData({
                    ...data,
                    ["resumeUrl"]: resp.data.filename,
                    ["email"]: resp.data.resumedata.email,
                    ["name"]: resp.data.resumedata.name
                })
            } else {
                setData({
                    ...data,
                    ["resumeUrl"]: resp.data.filename
                })
            }
            toast.success(resp.message);
        }
        else {
            toast.error(resp.message, 'error')
        }
    };

    const handleChangeProfiler = (value) => {
        setData({
            ...data,
            jobID: value.jobID
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {};
        if (data.candidateID) {
            for (const [key, value] of Object.entries(data)) {
                payload[key] = value;
            }
        }
        else {
            for (const [key, value] of Object.entries(data)) {
                if (key !== "_id") {
                    payload[key] = value;
                }
            }
        }
        const { status, message } = await fetchData({ url: URLS.addCandidate, method: 'post', body: payload })
        if (status === 200) {
            toast.success(message);
            resetModel();
            reloadContent();
            onHide(false);
        }
        else {
            toast.error(message, 'error')
        }
    }

    const resetModel = () => {
        setData({
            candidateID: "",
            jobTitle: '',
            experienceFrom: '',
            experienceTo: '',
            skills: '',
            requirements: '',
            jobID: jobs.length ? jobs[0]._id : "",
            name: '',
            email: '',
            phone: '',
            resumeUrl: '',
            experience: '',
            PAN: '',
            AADHAR: '',
            currentCTC: '',
            expectedCTC: '',
            noticePeroid: '',
            willJoinEarly: '',
            employeeID: '',
        })
    }

    return (
        <>
            <Modal size="xl" show={show} onHide={() => { onHide(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{data.candidateID ? 'Update' : 'Add'} Candidate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box component={'form'} onSubmit={handleSubmit} pt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={8} >
                                <Box pl={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} >
                                            <TextField fullWidth label="Profiler" required size="small" select value={data.jobID}>
                                                {jobs.map((option) => (
                                                    <MenuItem key={"select"} value={option.jobID} onClick={() => handleChangeProfiler(option)}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="Name"
                                            //  oninvalid="this.setCustomValidity('Enter User Name Here')"

                                                value={data.name} onChange={handleOnChange} name={'name'} required size="small" />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField type={"email"} onChange={handleOnChange} value={data.email} fullWidth label="Email" name={'email'} required size="small" />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField required onChange={handleOnChange} value={data.phone} fullWidth label="Phone" name={'phone'} size="small" inputProps={{ maxLength: 10 }} />
                                        </Grid>

                                        <Grid item xs={6} >
                                            <TextField fullWidth onChange={handleOnChange} value={data.experience} label="Experience" size="small" name='experience' />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="PAN" error={panErrMsg}
                                                helperText={panErrMsg ? 'pan number invalid' : ''}
                                                inputProps={{ maxLength: 10 }}
                                                size="small" value={data.PAN} onChange={handleOnChange} name="PAN"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="AADHAR" name={'AADHAR'} error={aadharErrMst}
                                                inputProps={{ maxLength: 12 }} value={data.AADHAR} onChange={handleOnChange}
                                                helperText={aadharErrMst ? 'Invalid Aadhar Number' : ""}
                                                size="small" />
                                        </Grid>
                                        {!(data.AADHAR.length > 0 || data.PAN.length > 0) &&
                                            <Grid item xs={12}>
                                                <Typography ml={1} align='left' variant='body2' color={'#d32f2f'}>PAN  or AADHAR  Required</Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="Current CTC" onChange={handleOnChange} value={data.currentCTC} name={'currentCTC'} size="small" />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="Expected CTC" onChange={handleOnChange} value={data.expectedCTC} name={'expectedCTC'} size="small" />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="Notice Period(Days)" value={data.noticePeroid} onChange={handleOnChange} name={'noticePeroid'} size="small" />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField fullWidth label="Will join early(Days)" value={data.willJoinEarly} onChange={handleOnChange} name={'willJoinEarly'} size="small" />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField fullWidth multiline rows={4} label="Skills" value={data.skills} onChange={handleOnChange} name={'skills'} size="small" required />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Container >
                                    <div className="image-upload">
                                        <label style={{ "cursor": "pointer" }} htmlFor="file_upload">
                                            <img src="" alt="" className="uploaded-image" />
                                            <div className="h-100">
                                                <div className="dplay-tbl">
                                                    <div className="dplay-tbl-cell"><i className="fa fa-cloud-upload"></i>
                                                        <h5><b>Choose Your CV to Upload</b></h5>
                                                        <h6 className="mt-3">Or Drop Your CV Here</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <input onChange={onFileUpload} accept=".pdf,.docx" data-required="image" type="file" name="image_name" id="file_upload" className="image-input" data-traget-resolution="image_resolution" />
                                        </label>
                                    </div>
                                    {data.resumeUrl && (<div className="d-flex align-items-center">
                                        <a href={downloadLink + data.resumeUrl} target="_blank" rel="noopener noreferrer">
                                            {data.resumeUrl}
                                        </a>
                                        <ToolTip title="Delete Resume" placement="top">
                                            <CloseOutlinedIcon className="text-danger ms-2" style={{ cursor: "pointer" }} onClick={() => setData({
                                                ...data,
                                                resumeUrl: ''
                                            })} />
                                        </ToolTip>
                                    </div>)}
                                </Container>
                            </Grid>
                        </Grid>
                        <Box display={'flex'} justifyContent={'end'} pt={4} pb={2} pr={3}>
                            <Box mr={2}>
                                <Button variant="contained" color='inherit' onClick={() => { onHide(false) }}>
                                    Close
                                </Button>
                            </Box>
                            <Box>
                                <Button type="submit" variant="contained" disabled={aadharErrMst || panErrMsg || !(data.AADHAR.length === 12 || data.PAN.length === 10) ? true : false} >
                                    {data.candidateID ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCandidate;