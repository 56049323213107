import React from 'react'
import { NavLink } from "react-router-dom";
import Wmlogo from "../../assets/images/logo.png"
import "./Navbar.scss";

const NabBar = () => {
  return (
    <section className='navbar-section p-0'>
      <div className='container'>
        <nav className="navbar navbar-expand-lg navbar-light">
          <NavLink to="/" className='navbar-brand'>
            <img src={Wmlogo} height={40} alt="logo" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <div className='navbar_icon'>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
              <li className="nav-item">
                <NavLink to='/login' className='nav-link me-2 text-end'>
                  Login
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
  )
}

export default NabBar