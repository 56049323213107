import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from '@mui/icons-material/Error';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

export const InterViewStatus = ({ status }) => {
    if (status === "Rejected") {
        return <><CancelIcon className="text-danger me-2" /> {status}</>
    }
    else if (status === "Selected") {
        return <><CheckCircleIcon className="text-success me-2" /> {status}</>
    }
    else if (status === "Hold") {
        return <> <ErrorIcon className="text-warning me-2" /> {status}</>
    } else {
        return <><ReplayCircleFilledIcon className="text-secondary me-2" />In-Progress</>
    }
}

