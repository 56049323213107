import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { holidayList } from '../../helpers/Holidays';
import moment from 'moment';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const headers = [
    { field: 'holiday', headerName: 'Holiday' },
    { field: 'date', headerName: 'Date' },
    { field: 'dayOfWeek', headerName: 'Day Of Week' },
];




const Holidays = () => {
    const [value, setValue] = useState(0);
    const [upcomingHolidays, setUpcomingHolidays] = useState([]);
    const [pastHolidays, setPastHolidays] = useState([]);

    useEffect(() => {
        const filteredHolidays = holidayList.filter(day => {
            if (value === 0) {
                return moment(day.date, 'DD/MM/YYYY').isSameOrAfter(new Date());
            } else {
                return moment(day.date, 'DD/MM/YYYY').isBefore(new Date());
            }
        })

        if (value === 0) {
            setUpcomingHolidays([...filteredHolidays])
        } else {
            setPastHolidays([...filteredHolidays])
        }

    }, [value])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const holidayTable = (rows) => <TableContainer component={Paper} sx={{ boxShadow: 'unset' }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Holiday</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}> Day Of Week</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.id} >
                        <TableCell> {row.holiday} </TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.dayOfWeek}</TableCell>

                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>



    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Upcoming Holidays" {...a11yProps(0)} />
                    <Tab label="Past Holidays" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {holidayTable(upcomingHolidays)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {holidayTable(pastHolidays)}
            </TabPanel>
        </Box>
    );
}

export default Holidays;