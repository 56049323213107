import React from 'react'
import { ScheduleMeeting } from "react-schedule-meeting";
import Select from "react-select"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const availableTimeslots = [0, 1, 2, 3, 4, 5].map((id) => {
  return {
    id,
    startTime: new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        9,
        0,
        0,
        0
      )
    ),
    endTime: new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        17,
        0,
        0,
        0
      )
    )
  };
});


const ScheduleInterview = ({ interviewSchedule }) => {
  
  const options = [
    { value: 'John', label: 'John' },
    { value: 'Larry', label: 'Larry' },
  ]

  return (
    <div class="card">
      <div className='container'>
        <div className='row'>
          <div className='col-5 pt-2'>
            <Select options={options} />
          </div>
          <div className='col-7 mt-2 text-end'>
            <ArrowBackIcon onClick={interviewSchedule} />
          </div>
        </div>
      </div>
      <ScheduleMeeting
        borderRadius={10}
        primaryColor="#3f5b85"
        eventDurationInMinutes={30}
        availableTimeslots={availableTimeslots}
        // onStartTimeSelect={console.log}
      />
    </div>
  )
}

export default ScheduleInterview
