import { Modal } from "react-bootstrap";
import { Chip } from "@mui/material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";

export default function EmpDetails({ show, data, hide }) {
    return (
        <>
            <Modal show={show} onHide={() => { hide() }} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {data.employeeName} Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="d-sm-flex d-flex">
                                        <div className="d-flex">
                                            <div
                                                className="bg-secondary me-3"
                                                style={{
                                                    width: "60px",
                                                    height: "60px",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <img
                                                    className="w-100 rounded-circle"
                                                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                                />
                                            </div>
                                            <div>
                                                <div className="d-sm-flex mt-2">
                                                    <div className="align-items-center me-3">
                                                        <BadgeOutlinedIcon className="text-secondary" />
                                                        <span className="m-0 ps-1">
                                                            {data.employeeCode}
                                                        </span>
                                                    </div>
                                                    <div className=" align-items-center me-3">
                                                        <PermIdentityIcon className="text-secondary" />
                                                        <span className="m-0 ps-1">
                                                            {data.employeeName}
                                                        </span>
                                                    </div>
                                                    <div className=" align-items-center me-3">
                                                        <AlternateEmailIcon className="text-secondary" />
                                                        <span className="m-0 ps-1">
                                                            {data.emailID}
                                                        </span>
                                                    </div>
                                                    <div className=" align-items-center me-3">
                                                        <PhoneInTalkOutlinedIcon className="text-secondary" />
                                                        <span className="m-0 ps-1">
                                                            {data.mobile}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <b>Manager Name: </b>
                                    {data.managerName}
                                </div>
                                <div className="col-md-4">
                                    <b>Department: </b>
                                    {data.department && data.department}
                                </div>
                                <div className="col-md-4">
                                    <b>Designation : </b>
                                    {data.designation && data.designation}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <b>Skills: </b>
                                    {
                                        data.skills && data.skills.length > 0 ?
                                            data.skills.map((item) =>

                                                <Chip className="me-1" variant="soft" label={item.name}></Chip>
                                            ) : "No Skills Entered"
                                    }
                                </div>
                                <div className="col-md-6">
                                    <b>Desired Skills: </b>
                                    {
                                        data.desiredSkills && data.desiredSkills.length > 0 ?
                                            data.desiredSkills.map((item) =>

                                                <Chip className="me-1" variant="soft" label={item}></Chip>
                                            ) : "No Desired Skills Entered"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}