import React, { useEffect, useState } from 'react'
import moment from 'moment'
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";

import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import '../../components/Notifications/Notifications.scss'
import { Box, Button } from '@mui/material';
import NotificationPanel from '../../components/Notifications/Notification-Panel';

const DashboardNotifications = () => {

    const { fetchData } = useAxios();

    const [allNotifications, setAllNotifications] = useState([]);
    const [openNotitficationPanel, setOpenNotificationPanel] = useState(false);
    const [data, setData] = useState("")
    const getValue = (person) => {
        setData(person)
    }

    useEffect(() => {
        getAllNotifications();
    }, []);


    const getAllNotifications = async () => {
        const response = await fetchData({ url: URLS.getMyNotifications, method: "get" });
        if (response.status === 200) {
            const allNotices = response.data.slice(0, 4);
            allNotices.forEach((notify) => {
                notify.icon = null;
                if (notify.notification.title === 'Leave Request') {
                    notify.icon = <div className="rounded-circle bg-warning d-flex justify-content-center align-items-center p-2">
                        <DateRangeIcon className="text-white" /></div>
                } else if (notify.notification.title === 'Work Report') {
                    notify.icon = <div className="rounded-circle bg-primary d-flex justify-content-center align-items-center p-2">
                        <AssignmentIndOutlinedIcon className="text-white" /></div>
                }
                else if (notify.notification.title === 'Project') {
                    notify.icon = <div className="rounded-circle bg-success d-flex justify-content-center align-items-center p-2">
                        <AccountTreeIcon className="text-white" /></div>
                }
                else if (notify.notification.title === 'Interview')
                    notify.icon = <div className="rounded-circle bg-secondary d-flex justify-content-center align-items-center p-2">
                        <PersonSearchIcon className="text-white" /></div>
            })
            setAllNotifications(allNotices)
        }
    }

    return (
        <div className="unread_notifications">
            {allNotifications && allNotifications.map((eachItem, index) => {
                return (
                    <div className="notification_space_unread shadow-sm" key={index}>
                        <div className="row">
                            <div className="col-2">
                                {eachItem.icon}
                            </div>
                            <div className="col-10 ps-0">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span><h6 className="mb-0">{eachItem.notification.title}</h6></span>
                                    <p className="mb-0 notification_date">{moment(eachItem.createdDate).format('DD/MM/YYYY h:mm A')}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="mb-0 body_notifications">{eachItem.notification.body}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            <Box textAlign={'center'} my={1}>
                {/* <Button variant='outlined' onClick={() => { setOpenNotificationPanel(true) }}>View All</Button> */}
                <button className='btn btn-primary' onClick={() => { setOpenNotificationPanel(true) }}>View All</button>
            </Box>

            <NotificationPanel getValue={getValue} openNotitficationPanel={openNotitficationPanel} setOpenNotificationPanel={setOpenNotificationPanel} defaultTab={1} />
        </div>
    )
}
export default DashboardNotifications