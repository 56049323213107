import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { encrypt, YYMMDD, timeView } from "../../helpers/utilities";
import { downloadLink } from '../../helpers/constFIles';
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ResumeViewModal from "../Recruiter/ResumeViewModal";
import MUILink from "@mui/material/Link";
import Button from '@mui/material/Button';
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";
import AssessmentIcon from '@mui/icons-material/Assessment';

const InterViewAsssignList = () => {

    const { fetchData } = useAxios();

    const [resumeView, setResumeView] = useState({
        filePath: "",
        filetype: "",
        resumeName: "",
    });

    const [pageNumber, setPageNumber] = useState(1);

    const [interViewList, setInterViewList] = useState([]);

    const [resumeShow, setResumeShow] = useState(false);

    const [loadSkeleton, setLoadSkeleton] = useState(true);

    useEffect(() => {
        getList()
    }, [])

    const getList = async () => {
        setLoadSkeleton(true);
        const resp = await fetchData({ url: `${URLS.assignInterViewList}`, method: 'get' });
        if (resp.status === 200) {
            setInterViewList(resp.data);
            setLoadSkeleton(false);
        }
    }

    const candidateResume = (data) => {
        setResumeView({
            ...resumeView,
            filePath: downloadLink + data.resumeUrl,
            filetype: data.resumeUrl.split(".").at(-1),
            resumeName: data.name,
        });
        setResumeShow(true);
    };
   
    const resumeHide = () => {
        setResumeShow(false);
    };

    const pull_data = (data) => {
        setPageNumber(data)
    }

    const countPerPage = 10;

    return (
        <div>
            <h4 className="title">My Interviews</h4>
            <div className="">
                {loadSkeleton ? <SkeletonLoader /> :
                    (interViewList.length > 0 ?
                        <div className="table-responsive bg-wht">
                            <table className="table">
                                <thead>
                                    <tr style={{ whiteSpace: 'nowrap' }}>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Experience</th>
                                        <th scope="col">Skills</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Resume</th>
                                        <th scope="col">Round Type</th>
                                        <th scope="col">Round No.</th>
                                        <th scope="col">status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        interViewList && interViewList.slice((pageNumber - 1) * countPerPage, pageNumber * countPerPage).map((cand,index) => {
                                            return (
                                                <tr className="border" key={index}>
                                                    <td>{cand.candidate.candidateID}</td>
                                                    <td>{cand.candidate.name}</td>
                                                    <td>{cand.candidate.email}</td>
                                                    <td>{cand.candidate.phone}</td>
                                                    <td>{cand.candidate.experience} Years</td>
                                                    <td style={{ minWidth: '200px', maxWidth: '200px' }}>{cand.candidate.skills}</td>
                                                    <td style={{ minWidth: '200px' }}>{YYMMDD(cand.startTime)} <br />{timeView(cand.startTime)} - {timeView(cand.endTime)}</td>
                                                    <td
                                                        onClick={(e) => {
                                                            candidateResume(cand.candidate);
                                                        }}
                                                    >
                                                        <MUILink><Button variant="text">Resume</Button></MUILink>


                                                    </td>
                                                    <td>{cand.roundType}</td>
                                                    <td>Round {cand.round}</td>
                                                    <td style={{ minWidth: '150px' }}>{cand.status === "Rejected" ? <CancelIcon className="text-danger me-2" /> : cand.status === 'Selected' ? <CheckCircleIcon className="text-success me-2" /> : cand.status === 'Hold' ? <ErrorIcon className="text-warning me-2" /> : <div className="d-flex"><AssessmentIcon className="text-primary"/><p className="text-dark">Not Assessed</p></div>}{cand.status}</td>
                                                    <td>
                                                        <Button variant="contained" className="p-0">
                                                            <Link to={`/recruiter/interviewer/${encrypt(cand.candidate.candidateID)}/${encrypt(cand.interViewID)}/${encrypt(cand.scheduledTo)}`} className="text-white p-2" style={{ textDecoration: 'none' }}>
                                                                Assess
                                                            </Link>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <ResumeViewModal
                                resumeData={resumeView}
                                show={resumeShow}
                                onHide={resumeHide}
                            />
                        </div> : <NoData />
                    )
                }
            </div>
            {interViewList && interViewList.length > 0 ?
                <PaginationGlobal count={Math.ceil(interViewList.length / countPerPage)}
                    pageNumber={pageNumber} func={pull_data} /> : ''}
        </div>
    )

}

export default InterViewAsssignList;