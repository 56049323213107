import React, { useEffect, useState } from 'react'
import { URLS } from '../../helpers/URLS';
import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import AdminHeader from '../AdminHeader/AdminHeader';
import AdminNav from '../AdminNav/AdminNav';
import useAxios from '../../helpers/Hooks/useAxios';
import './Dashboard.scss';

const Header = () => {
   
    const { fetchData } = useAxios();
   
    const navigate = useNavigate();
   
    const [toggle, setToggle] = useState(true);
   
    const [user, setUser] = useState({
        employee: {
            employeeCode: "",
            employeeName: "",
            id: "",
            totalData: {}
        },
        roles: {
            daily: false,
            isTeam: false,
            isProjects: false
        },
        menu: []
    })

    const getUserProfle = async () => {
        const resp = await fetchData({ url: URLS.user, method: 'get' });
        if (resp.status === 200) {
            setUser(resp.data)
        } else {
            navigate("/")
        }
    }
   
    useEffect(() => {
        getUserProfle();
    }, [])

    return (
        <div className='dashboard_section'>
            <div className='admin-bg d-flex p-0'>
                <AdminNav setToggle={setToggle} toggle={toggle} user={user.employee} menu={user.menu} />
                <div id="content" className={`s${toggle ? '' : 'active'}`} style={{ backgroundColor: " bg-light" }}>
                    <AdminHeader setToggle={setToggle} toggle={toggle} user={user} />
                    <div className='container outlet_scroll p-3'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
