import React from "react";
import moment from 'moment'

const AllNotifications = ({ allNotifications }) => {
    return (
        <div className="unread_notifications">
            {allNotifications && allNotifications.map((eachItem,index) => {
                return (
                    <div className="notification_space_unread shadow-sm" key={index}>
                        <div className="row">
                            <div className="col-2">
                                {eachItem.icon}
                            </div>
                            <div className="col-10 ps-0">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span><h6 className="mb-0">{eachItem.notification.title}</h6></span>
                                    <p className="mb-0 notification_date">{moment(eachItem.createdDate).format('DD/MM/YYYY h:mm A')}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="mb-0 body_notifications">{eachItem.notification.body}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default AllNotifications