import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import Select from 'react-select';
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";

const ManageTeams = () => {

    const { fetchData } = useAxios();

    const [teamsList, setTeamsList] = useState([]);

    const [pagination, setpagination] = useState([])

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [employeesList, setEmployeesList] = useState([]);

    const [teamModel, setTeamModel] = useState(false);

    const [loadSkeleton, setLoadSkeleton] = useState(true);

    const [pageNumber, setPageNumber] = useState(1);

    const [teamData, setTeamData] = useState({
        companyName: "",
        countryCode: "",
        department: "",
        designation: "",
        emailID: "",
        employeeCode: "",
        employeeName: "",
        list: [],
        managerEmail: "",
        managerName: "",
        mobile: "",
        officeLocation: "",
        teamsList: [],
        workLocation: ""
    })

    const getEmployeesList = async () => {
        const resp = await fetchData({
            url: URLS.searchEmployees, method: 'post', body: {
                search: ""
            }
        });
        if (resp.status === 200) {
            setEmployeesList(resp.data);
        }
    }

    const getTeamsList = async () => {
        setLoadSkeleton(true);
        const resp = await fetchData({ url: URLS.getTeamsList, method: 'get' });
        if (resp.status === 200) {
            setTeamsList(resp.data)
            const count = Math.ceil(resp.data.length / rowsPerPage);
            const pageNumbers = [];
            for (let i = 0; i < count; i++) {
                pageNumbers.push(i + 1);
            }
            setpagination(pageNumbers);
            setLoadSkeleton(false);
        }
    }

    useEffect(() => {
        getTeamsList();
        getEmployeesList();
    }, []);

    const teamMembersChange = async (value) => {
        try {
            setTeamData({
                ...teamData,
                list: value.map(x => {
                    return { employeeCode: x.employeeCode }
                })
            })
        } catch (err) {
            console.log("teamMembersChange Error:", err.message)
        }
    }

    const teamViewModel = () => setTeamModel(!teamModel);

    const getPageNo = (data) => {
        setPageNumber(data)
    }

    const countPerPage = 10;

    return (
        <div className='content'>
            <h4 className='title'>Manage Teams</h4>
            <div className="shadow-sm bg-white">
                <div className="card-body">
                    {
                        loadSkeleton ? <SkeletonLoader /> : (
                            teamsList.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Employee ID</th>
                                                <th scope="col">Employee Name</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Mobile</th>
                                                <th scope="col">Manager Name</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamsList && teamsList.slice((pageNumber - 1) * countPerPage,
                                                pageNumber * countPerPage).map((emp) => {
                                                    return <tr key={emp.employeeCode}>
                                                        <td scope="row">{emp.employeeCode}</td>
                                                        <td>{emp.employeeName}</td>
                                                        <td>{emp.designation}</td>
                                                        <td>{emp.emailID}</td>
                                                        <td>{emp.mobile}</td>
                                                        <td>{emp.managerName}</td>
                                                        <td>
                                                            <Button type="button" variant="contained" onClick={(e) => {
                                                                setTeamData(emp)
                                                                teamViewModel()
                                                            }} className="me-2 team_button">Team ({emp.teamsList.length})</Button>
                                                        </td>
                                                    </tr>
                                                })}
                                        </tbody>
                                    </table>
                                    {teamsList && teamsList.length > 0 ? <PaginationGlobal count={Math.ceil(teamsList.length / countPerPage)}
                                        pageNumber={pageNumber} func={getPageNo} /> : ""}
                                </div>
                                : <NoData />
                        )
                    }
                </div>
            </div>
            <Modal show={teamModel} onHide={teamViewModel} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Manage Team ({teamData.employeeName} - {teamData.employeeCode})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-md-12 row'>
                        <div className='col-md-12'>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Team Members ({employeesList.filter(x => teamData.list.find(y => x.employeeCode === y.employeeCode)).length})</label>
                            <Select
                                isMulti
                                value={employeesList.filter(x => teamData.list.find(y => x.employeeCode === y.employeeCode))}
                                options={employeesList}
                                onChange={teamMembersChange}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="inherit" onClick={teamViewModel} className="me-3">
                        Close
                    </Button>
                    <Button onClick={async (e) => {
                        const resp = await fetchData({ url: URLS.manageTeam, method: 'post', body: teamData });
                        if (resp.status === 200) {
                            getTeamsList()
                            teamViewModel()
                        }
                    }} variant="contained" >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ManageTeams