import React, { useState } from 'react'
import { Drawer, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import NotificationTab from './NotificationTabs';

const NotificationPanel = (props) => {

  const { openNotitficationPanel, setOpenNotificationPanel, getValue, defaultTab = 0 } = props;

  const [number, setNumber] = useState('')

  const list = () => (
    <Box
      sx={{ width: 400 }}
      role="presentation"
      onKeyDown={() => setOpenNotificationPanel(false)}
    >
      <div className="card sticky-top">
        <div className="card-body" style={{ backgroundColor: "#1e293b" }}>
          <div className="d-flex">
            <h4 className="card-title text-white">Notifications</h4>
            <div className="ms-auto">
              <ClearIcon
                className="mt-2 text-white"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenNotificationPanel(false)}
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationTab number={number} setNumber={setNumber} getValue={getValue} setOpenNotificationPanel={setOpenNotificationPanel}  defaultTab={defaultTab} />
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{ width: 450 }}
        anchor="right"
        open={openNotitficationPanel}
        onClose={() => setOpenNotificationPanel(false)}
      >
        {list()}
      </Drawer>
    </div>
  )
}

export default NotificationPanel;
