import React, { useState, useEffect } from "react";
import { lightBlue } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../helpers/URLS';
import { Avatar } from '@mui/material';
import useAxios from '../../helpers/Hooks/useAxios';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import ManIcon from '@mui/icons-material/Man';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import UserProfileSkills from "./UserProfileSkills";
import ToolTip from '../../Tolltip'
import './Profile.scss'

const Profile = () => {

    const { fetchData } = useAxios();

    const navigate = useNavigate();

    const [user, setUser] = useState({})

    const getUserProfle = async () => {
        const resp = await fetchData({ url: URLS.user, method: 'get' });
        if (resp.status === 200) {
            setUser(resp.data.employee)
        } else {
            navigate("/")
        }
    }

    useEffect(() => {
        getUserProfle();
    }, [])

    return (
        <div className="row mb-5 pb-4">
            <div className="col-sm-12 col-lg-3 mb-2">
                <div className="card d-flex align-items-center justify-content-center pb-4">
                    <div className="profile_picture_profile me-3 mt-4 mb-3">
                        <Avatar sx={{ bgcolor: lightBlue[900] }} className="profile_img_letter"><h1>{user?.employeeName?.substring(0, 1)}</h1></Avatar>
                        <button className="edit_profile_pic btn btn-primary rounded-circle p-0">
                            <label for="profilepicture">
                                <EditIcon className="p-1" />
                            </label>
                        </button>
                        <input type="file" id="profilepicture" className="d-none" />
                    </div>
                    <div className="bg-white rounded header d-flex justify-content-between align-items-center">
                        <ToolTip Text={user.employeeName}><h5 className="emp_name">{user.employeeName}</h5></ToolTip>
                    </div>
                    <div className="mt-2 personal_skills">
                        <div className="personal_info ">
                            <hr></hr>
                            {user.emailID ?
                                <div className="d-flex mx-4">
                                    <div><EmailIcon className="icon my-2" /></div>
                                    <div><ToolTip Text={user.emailID}><p className="emp_email my-2 mx-1">{user.emailID}</p></ToolTip></div>
                                </div> : null}
                            {user.mobile ? <div class="d-flex mx-4">
                                <div><StayCurrentPortraitIcon className="icon my-2"></StayCurrentPortraitIcon></div>
                                <p className="my-2 mx-1">{user.countryCode}{' '}{user.mobile}</p>
                            </div> : null}
                            {user.gender ? <div class="d-flex mx-4">
                                <div><ManIcon className="icon my-2"></ManIcon></div>
                                <p class="toolTip_text my-2 mx-1">{user.gender}</p>
                            </div> : null}
                            {user.address ? <div class="d-flex mx-4">
                                <div class=""><PersonPinCircleIcon className="icon my-2"></PersonPinCircleIcon></div>
                                <p class="emp_name my-2 mx-1">{user.address}</p>
                            </div> : null}
                            <hr></hr>
                            <div className="social_icons d-flex justify-content-center mb-3">
                                <a href="#" class="text-reset"><FacebookIcon className="m-3"></FacebookIcon></a>
                                <a href="#" class="text-reset"><TwitterIcon className="m-3"></TwitterIcon></a>
                                <a href="#" class="text-reset"><InstagramIcon className="m-3"></InstagramIcon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-lg-9">
                <div className="card mb-3 professional_details p-3">
                    <div className="bg-white rounded header d-flex justify-content-between align-items-center">
                        <p className="mb-3 h5">Professional Details </p>
                    </div>
                    <div className="h5 name mb-3 bg-light p-2">{user.employeeName}</div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center mb-3">
                                <div className="h6 m-0 fw-bold text-secondary mb-0 me-3">Employee ID</div>
                                <div className="d-flex align-items-center me-3">
                                    <IntegrationInstructionsIcon className="icon me-1"></IntegrationInstructionsIcon>
                                    <p className="m-0">{user.employeeCode}</p>
                                </div>
                                {user.address ? <div className="d-flex align-items-center">
                                    <PersonPinCircleIcon className="icon me-1"></PersonPinCircleIcon>
                                    <p className="m-0">{user.address}</p>
                                </div> : null}
                            </div>
                            <div>
                                <div className="d-flex mb-3">
                                    <p className="mb-0 me-3 fw-bold text-secondary">Manager: </p>
                                    <p className="m-0">{user.managerName}</p>
                                </div>
                                {user.dob ? <div className="d-flex mb-3">
                                    <label className="mb-0 me-2 fw-bold text-secondary">Date Of Birth: </label>
                                    <p className="m-0">{user?.dob?.substring(0, 10)}</p>
                                </div> : null}
                                <div className="d-flex mb-3">
                                    <p className="mb-0 me-2 fw-bold text-secondary">Department: </p>
                                    <p className="m-0">{user.department}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="d-flex mb-3">
                                <p className="mb-0 me-2 fw-bold text-secondary">Company: </p>
                                <p className="m-0">{user.companyName}</p>
                            </div>
                            <div className="d-flex mb-3">
                                <p className="mb-0 me-2 fw-bold text-secondary">Manger Email: </p>
                                <p className="m-0">{user.managerEmail}</p>
                            </div>
                            <div className="d-flex mb-3">
                                <p className="mb-0 me-2 fw-bold text-secondary">Designation: </p>
                                <p className="m-0">{user.designation}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <UserProfileSkills user={user} reloadProfile={getUserProfle} />
            </div>
        </div>
    )
}

export default Profile